import React, { useEffect, useState } from "react";
import HttpService from "../../../hooks/Https-services";
import { API_ROUTES, APP_ROUTES } from "../../../utils/constants";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Skeleton,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import useCommonDetails from "../../../hooks/useCommonDetails";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import DriverListTable from "./DriverListTable";
import WestIcon from "@mui/icons-material/West";
import Style from "../../../style/Common.style";

const ApplyDriverList = () => {
  const [params, setParams] = useSearchParams();
  const { id } = useParams();
  const [load, setLoad] = useState(true);
  const [load2, setLoad2] = useState(false);
  const [data, setData] = useState<any>(null);
  const commonDetial = useCommonDetails();
  const [tabs, setTabs] = useState(0);
  const [isReferes, setIsReferes] = useState(false);
  const navigate = useNavigate();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabs(newValue);
    setLoad(true);
    getDriverList(newValue);
  };

  const getDriverList = async (code: any) => {
    try {
      const data = {
        query: {
          advertiserId: commonDetial.id,
          advertiseId: Number(id),
          isDeleted: false,
          status: code,
        },
        options: {
          select: ["id", "userId"],
          include: [
            {
              association: "driver",
              required: true,
              model: "user",
            },
          ],
          page: 1,
          paginate: 10,
        },
        isCountOnly: false,
      };
      const res = await HttpService.post(API_ROUTES.GetDriverList, data);
      setData(res.data.data);
      setLoad(false);
    } catch (error) {
      setLoad(false);
      console.log(error);
    }
  };
  useEffect(() => {
    getDriverList(tabs);
  }, [tabs, isReferes]);

  return (
    <Box p={2}>
      <Box sx={Style.StickyHeaderStyle}>
        <Button
          variant="contained"
          onClick={() => navigate(APP_ROUTES.AdvertiserCampaign)}
        >
          <WestIcon />
          <Typography variant="body2">Back</Typography>
        </Button>
        <Typography variant="h5" my={1} mt={2} fontWeight={500}>
          Driver List
        </Typography>
        <Tabs
          value={tabs}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{ mb: 2 }}
        >
          <Tab label="Applied" value={0} />
          <Tab label="Approved" value={1} />
          <Tab label="Rejected" value={2} />
        </Tabs>
      </Box>
      <Box>
        {!load ? (
          data?.length > 0 ? (
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead
                  sx={{
                    ".MuiTableCell-root": {
                      px: "5px",
                    },
                  }}
                >
                  <TableRow>
                    <TableCell align="center">No</TableCell>
                    <TableCell>User Name</TableCell>
                    <TableCell>Full Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Phone Number</TableCell>
                    <TableCell>Location</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {data?.map((ele: any, idx: any) => (
                    <DriverListTable
                      ele={ele}
                      idx={idx}
                      tabs={tabs}
                      setIsReferes={setIsReferes}
                      isReferes={isReferes}
                    />
                  ))}
                </TableBody>
              </Table>
              {/* <TablePagination
    rowsPerPageOptions={[5, 10, 25]}
    component="div"
    rowsPerPage={row}
    count={total}
    page={page - 1}
    onPageChange={handleChangePage}
    onRowsPerPageChange={handleChangeRowsPerPage}
  /> */}
            </TableContainer>
          ) : (
            <Typography variant="body1" textAlign="center">
              No data found
            </Typography>
          )
        ) : (
          <Skeleton variant="rounded" height={500} width={"100%"} />
        )}
      </Box>
    </Box>
  );
};

export default ApplyDriverList;
