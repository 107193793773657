import {
  Box,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Grid,
  Button,
  Stack,
  IconButton,
  MenuItem,
  Menu,
} from "@mui/material";
import React from "react";
import HttpService from "../../../hooks/Https-services";
import { API_ROUTES, APP_ROUTES } from "../../../utils/constants";
import { toast } from "react-toastify";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import NoCrashOutlinedIcon from "@mui/icons-material/NoCrashOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";

const Campaigns = (props: any) => {
  const { url, name, location, cpm, added, car_type, id } = props;
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box>
      <Card
        sx={{
          borderRadius: "10px",
          border: "1px solid #0096FF6e ",
        }}
      >
        <CardMedia
          sx={{
            height: 240,
            ":hover": {
              cursor: "pointer",
            },
          }}
          image={url}
          onClick={() =>
            navigate(APP_ROUTES.ApplyDriverList.replace(":id", id))
          }
        />
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={10.5}>
              <Typography variant="h6" sx={{ fontWeight: "600" }}>
                {name}
              </Typography>
            </Grid>
            <Grid item xs={1.5}>
              <Box>
                <IconButton aria-label="settings" onClick={handleClick}>
                  <MoreVertIcon />
                </IconButton>
              </Box>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                sx={{
                  "& .MuiPaper-root": {
                    minWidth: 180,
                  },
                }}
              >
                <MenuItem
                  onClick={() => {
                    navigate(APP_ROUTES.EditCampaign.replace(":id", id));
                  }}
                >
                  Edit
                </MenuItem>
              </Menu>
            </Grid>
            <Grid item xs={6} sx={{ flexWrap: "x`" }}>
              <Stack spacing={1} direction="row" alignItems="center">
                <AttachMoneyOutlinedIcon />
                <Typography variant="body2" sx={{ fontWeight: "600" }}>
                  Cpm :
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="body2"
                color={"gray"}
                sx={{ fontWeight: "500" }}
              >
                {cpm}$
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Stack spacing={1} direction="row" alignItems="center">
                <AccountCircleOutlinedIcon sx={{ fontSize: "22px" }} />
                <Typography variant="body2" sx={{ fontWeight: "600" }}>
                  Applicants :
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="body2"
                component="span"
                color={"gray"}
                sx={{ fontWeight: "500" }}
              >
                {added}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Stack spacing={1} direction="row" alignItems="center">
                <LocationOnOutlinedIcon sx={{ fontSize: "22px" }} />
                <Typography variant="body2" sx={{ fontWeight: "600" }}>
                  Location :
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="body2"
                component="span"
                color={"gray"}
                sx={{ fontWeight: "500" }}
              >
                {location}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Stack spacing={1} direction="row" alignItems="center">
                <NoCrashOutlinedIcon sx={{ fontSize: "22px" }} />
                <Typography variant="body2" sx={{ fontWeight: "600" }}>
                  Car Type :
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="body2"
                component="span"
                color={"gray"}
                sx={{ fontWeight: "500" }}
              >
                {car_type}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Campaigns;
