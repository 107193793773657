import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import DriverListTable from "../AdvertiserCampaign/ApplyDriverList/DriverListTable";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import fileIco from "../../assets/images/fileIcon.png";

const DriverContract = () => (
  <Box p={2}>
    <Typography variant="h5" mb={1} fontWeight={500}>
      Campaing Contracts
    </Typography>
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead
          sx={{
            ".MuiTableCell-root": {
              px: "5px",
            },
          }}
        >
          <TableRow>
            <TableCell align="center"></TableCell>
            <TableCell>Campaign Name</TableCell>
            <TableCell>Starting Date</TableCell>
            <TableCell>Cpm</TableCell>
            <TableCell>Ending Date</TableCell>
            <TableCell>Location</TableCell>
            <TableCell align="center">Action</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow
            sx={{
              "&:nth-of-type(odd)": {
                backgroundColor: "#cfebff",
              },
              "&:last-child td, &:last-child th": {
                border: 0,
              },
            }}
          >
            <TableCell align="center">
              <Box component="img" src={fileIco} fontSize="large" width={40} />
            </TableCell>
            <TableCell>Demo</TableCell>
            <TableCell>25 Dec, 2022</TableCell>
            <TableCell>200$</TableCell>
            <TableCell>26 Dec, 2022</TableCell>
            <TableCell>Rajkot</TableCell>
            <TableCell align="center">
              <Button variant="contained" size="small">
                Download 📄
              </Button>
            </TableCell>
          </TableRow>
          <TableRow
            sx={{
              "&:nth-of-type(odd)": {
                backgroundColor: "#cfebff",
              },
              "&:last-child td, &:last-child th": {
                border: 0,
              },
            }}
          >
            <TableCell align="center">
              <Box component="img" src={fileIco} fontSize="large" width={40} />
            </TableCell>
            <TableCell>Demo</TableCell>
            <TableCell>25 Dec, 2022</TableCell>
            <TableCell>200$</TableCell>
            <TableCell>26 Dec, 2022</TableCell>
            <TableCell>Ahmedabad</TableCell>
            <TableCell align="center">
              <Button variant="contained" size="small">
                Download 📄
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  </Box>
);

export default DriverContract;
