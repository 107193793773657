import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import bg2 from "../../../assets/images/car_campaign.jpg";
import CircleIcon from "@mui/icons-material/Circle";
import moment from "moment";
import HttpService from "../../../hooks/Https-services";
import { API_ROUTES } from "../../../utils/constants";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

const DriverUploadCard = (props: any) => {
  const { date, image, ids } = props;
  const [imageData, setImageData] = useState(image);
  const [imgId, setimgId] = useState(ids);
  const [isLoading, setLoading] = useState(false);
  const { id } = useParams();
  // const handleFileChange = async (event: any) => {
  //   if (!event.target.files[0]) {
  //     toast.error("Please Select Image");
  //     return;
  //   }
  //   try {
  //     setLoading(true);
  //     const formData = new FormData();
  //     formData.append("advertiseId", id + "");
  //     formData.append("Images", event.target.files[0]);
  //     formData.append("createdAt", date);
  //     const res = await HttpService.post(
  //       API_ROUTES.DailyCampaignImage,
  //       formData
  //     );
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //     console.log(error);
  //   }
  // };
  const handleFileChange = async (event: any) => {
    if (!event.target.files[0]) {
      toast.error("Please Select Image");
      return;
    }
    try {
      const files = event.target.files;
      const formData = new FormData();
      setLoading(true);
      if (imgId) {
        formData.append("reportId", imgId);
        formData.append("Images", event.target.files[0]);
        for (let i = 0; i < files.length; i++) {
          formData.append("Images", files[i]);
        }

        const res = await HttpService.post(
          API_ROUTES.DriverCampaignImage,
          formData
        );
        setImageData(res.data.data.Images);
      } else {
        formData.append("advertiseId", id + "");
        for (let i = 0; i < files.length; i++) {
          formData.append("Images", files[i]);
        }
        formData.append("createdAt", date);
        const res = await HttpService.post(
          API_ROUTES.DailyCampaignImage,
          formData
        );
        setImageData(res.data.data.Images);
        setimgId(res.data.data?.id);
      }
      console.log(event.target.files);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  return (
    <Box>
      <Box mt={2} sx={{ display: "flex", justifyContent: "space-between" }}>
        <Stack spacing={1} direction="row" alignItems="center">
          <CircleIcon fontSize="small" sx={{ color: "#0096FF" }} />
          <Typography variant="body1" fontWeight={500}>
            {date}
          </Typography>
        </Stack>
        <LoadingButton
          component="label"
          loading={isLoading}
          variant="contained"
        >
          Upload Image
          <input
            accept="image/*"
            hidden
            id="raised-button-file"
            onChange={handleFileChange}
            type="file"
            multiple
          />
        </LoadingButton>
      </Box>
      <Box sx={{ display: "flex" }}>
        <Box>
          <Divider
            orientation="vertical"
            //   sx={{ mx: 1, borderColor: "black", borderStyle: "dashed" }}
            sx={{
              mx: 1,
              borderColor: "black",
              borderStyle: "dashed",
            }}
          />
        </Box>
        {imageData.length > 0 ? (
          <Box
            sx={{
              display: "flex",
              gap: 4,
              ml: 3,
              mt: 2,
              //   ml: 4,
            }}
          >
            {imageData.map((ele: any) => (
              <Box component="img" src={ele.image} width="22%" />
            ))}
          </Box>
        ) : (
          <Box p={4} sx={{ margin: "0 auto" }}>
            <Typography variant="body1" fontWeight={400}>
              No Image Uploaded
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default DriverUploadCard;
