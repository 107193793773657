import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import HttpService from "../../hooks/Https-services";
import { API_ROUTES, APP_ROUTES, USER_ROLE } from "../../utils/constants";
import { usePlacesWidget } from "react-google-autocomplete";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import WestIcon from "@mui/icons-material/West";
import useCommonDetails from "../../hooks/useCommonDetails";

const CreateCampaign = () => {
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    reset,
    clearErrors,
    unregister,
    formState: { errors },
  } = useForm();
  const VisuallyHiddenInput = styled("input")`
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    white-space: nowrap;
    width: 1px;
  `;

  const navigate = useNavigate();
  const [car, setCar] = useState([0]);
  const [images, setImages] = useState<any>([]);
  const [load, setLoad] = useState(false);
  const userData = useCommonDetails();

  const handleCarChange = (event: any) => {
    const {
      target: { value },
    } = event;

    const newValue = value.length > 0 ? value.filter((e: any) => e != 0) : [0];
    setCar(
      // On autofill we get a stringified value.
      typeof newValue === "string" ? newValue.split(",") : newValue
    );
  };

  const handleOpenImage = (e: any) => {
    window.open(e);
  };
  const handleFileChange = async (event: any) => {
    if (!event.target.files[0]) {
      toast.error("Please Select Image");
      return;
    }

    const image = {
      file: event.target.files[0],
      url: URL.createObjectURL(event.target.files[0]),
    };
    setImages((prevImages: any) => [...prevImages, image]);
  };

  const handleDelete = (e: any) => {
    const temp = images.filter((ele: any) => ele.url != e);
    setImages(temp);
  };

  const toAddress = (place: any) => {
    const { lat, lng } = place.geometry.location;
    const location = { lat: lat(), lon: lng() };
    return extractAddress(place.address_components, location);
  };
  const extractAddress = (addresses: any[], location: any) => {
    const address = {
      location,
      country: extractAddressData("country", addresses),
      street1: extractAddressStreetData(addresses),
      street2: extractAddressData("sublocality_level_2", addresses),
      city: extractAddressData("locality", addresses),
      state: extractAddressData("administrative_area_level_1", addresses),
      zip: extractAddressData("postal_code", addresses),
    };

    return address;
  };
  const extractAddressData = (name: string, addresses: any[]) =>
    addresses.find((c) => c.types.includes(name))?.long_name || "";

  const extractAddressStreetData = (addresses: any[]) => {
    const street = extractAddressData("sublocality_level_1", addresses);
    if (street) {
      const route = extractAddressData("route", addresses);
      if (route) {
        return `${street} ${route}`;
      }
    }
    return "";
  };
  const checkKeyDown = (e: any) => {
    if (e.key === "Enter") e.preventDefault();
  };
  const AutocompleteAdd = () => {
    const refs = usePlacesWidget<HTMLDivElement>({
      libraries: ["places"],
      options: { types: ["address"] },

      onPlaceSelected: (place: any) => {
        //set state
        let data = toAddress(place);
        setValue("fullLocation", data);
      },
    });

    return (
      <TextField
        fullWidth
        inputRef={refs.ref}
        label="Location"
        variant="outlined"
        autoComplete={"off"}
        size="medium"
        InputLabelProps={{ shrink: true }}
        InputProps={{
          style: { color: "black" },
        }}
        {...register("location", {
          required: "This field is required.",
        })}
        sx={{
          mb: 1,
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "black",
            },
          },
          "& .MuiFormLabel-root": {
            color: "black !important",
          },
        }}
        error={!!errors.location}
        helperText={
          errors.location && (
            <Typography variant="caption" sx={{ color: "red" }}>
              {errors.location.message as any}
            </Typography>
          )
        }
      />
    );
  };

  const onSubmit = async (data: any) => {
    if (!car) {
      toast.error("Please select car type.");
      return;
    }
    if (!images.length) {
      toast.error("Please select one or more then one images.");
      return;
    }
    setLoad(true);
    try {
      const files: any = [];
      images.map((e: any) => files.push(e.file));
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("cpm", data.cpm);
      formData.append("location", JSON.stringify(watch("fullLocation")));
      car.map((e: any) => formData.append("car_type", e));
      files.map((e: any, i: any) => {
        formData.append("adImages", files[i]);
      });
      const res = await HttpService.post(API_ROUTES.CreateCampaign, formData);
      toast.success("Campaign created successfully");
      reset();
      setImages([]);
      setCar([0]);
      navigate(APP_ROUTES.AdvertiserCampaign);
      setLoad(false);
    } catch (error) {
      console.log(error);
      setLoad(false);
    }
  };
  return (
    <Box p={2}>
      {userData.userType != USER_ROLE.ADMIN && (
        <Button
          variant="contained"
          onClick={() => navigate(APP_ROUTES.AdvertiserCampaign)}
        >
          <WestIcon />
          <Typography variant="body2">Back</Typography>
        </Button>
      )}

      {userData.userType == USER_ROLE.ADMIN && (
        <Button
          variant="contained"
          onClick={() => navigate(APP_ROUTES.CampaignsList)}
        >
          <WestIcon />
          <Typography variant="body2">Back</Typography>
        </Button>
      )}

      <Typography variant="h5" mb={1} mt={2} fontWeight={500}>
        Campaign Create
      </Typography>
      <Box>
        <Card
          sx={{
            px: { xs: 3, sm: 4 },
            py: { xs: 3, sm: 4 },
            maxWidth: "890px",
            width: { xs: "70%", sm: "80%" },
            borderRadius: "20px",
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <CardContent sx={{ p: "4px !important" }}>
              <Grid container spacing={2} mt={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Name"
                    autoComplete="off"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      style: { color: "black" },
                    }}
                    {...register("name", {
                      required: "This field is required.",
                    })}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "black",
                        },
                      },
                      "& .MuiFormLabel-root": {
                        color: "black !important",
                      },
                    }}
                    placeholder="Campaign Name/Ad Name"
                    error={!!errors.name}
                    helperText={
                      errors.name && (
                        <Typography variant="caption" sx={{ color: "red" }}>
                          {errors.name.message as any}
                        </Typography>
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Cpm (cost per mille)"
                    autoComplete="off"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      style: { color: "black" },
                    }}
                    {...register("cpm", {
                      required: "This field is required.",
                    })}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "black",
                        },
                      },
                      "& .MuiFormLabel-root": {
                        color: "black !important",
                      },
                    }}
                    placeholder="cost per mile"
                    error={!!errors.cpm}
                    helperText={
                      errors.cpm && (
                        <Typography variant="caption" sx={{ color: "red" }}>
                          {errors.cpm.message as any}
                        </Typography>
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Select
                    multiple
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={car}
                    onChange={handleCarChange}
                    size="medium"
                    fullWidth
                  >
                    <MenuItem value={0} sx={{ mt: 0.4 }} disabled>
                      Select Car Type
                    </MenuItem>
                    <MenuItem value={"Sedan"}>Sedan</MenuItem>
                    <MenuItem value={"XUV"}>XUV</MenuItem>
                    <MenuItem value={"Hatchback"}>Hatchback</MenuItem>
                    <MenuItem value={"SUV"}>SUV</MenuItem>
                    <MenuItem value={"Sports"}>Sports</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AutocompleteAdd />
                </Grid>
              </Grid>
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 2,
                  height: "300px",
                  width: "100%",
                  backgroundColor: "#ebf7ff",
                  borderRadius: "0.75rem",
                }}
              >
                <AttachmentIcon sx={{ fontSize: "26px" }} />
                <Typography
                  variant="body1"
                  sx={{ whiteSpace: "nowrap", fontWeight: 500 }}
                >
                  Add Campaign Images
                </Typography>
                <Typography
                  variant="caption"
                  sx={{ whiteSpace: "nowrap", fontWeight: 500 }}
                >
                  (First image will be thumbnail of the campaign)
                </Typography>
                <Button
                  component="label"
                  role={undefined}
                  tabIndex={-1}
                  variant="outlined"
                  sx={{ color: "nautral", textTransform: "none", mt: 1 }}
                  size="small"
                >
                  Upload+
                  <input
                    accept="image/*"
                    hidden
                    id="raised-button-file"
                    onChange={handleFileChange}
                    type="file"
                  />
                  <VisuallyHiddenInput type="file" />
                </Button>
              </Card>
              <Box
                sx={{
                  display: "flex",
                  mt: 2,
                  flexWrap: "wrap",
                  gap: 1.5,
                  ml: 0.5,
                }}
              >
                {images &&
                  images.map((e: any) => (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        position: "relative",
                        "&:hover": {
                          ".deleteBox": {
                            visibility: "visible",
                            background: "transparent",
                          },
                        },
                      }}
                    >
                      <Box
                        onClick={() => handleOpenImage(e.url)}
                        component="img"
                        height={"100%"}
                        sx={{
                          cursor: "pointer",
                          "&:hover": { opacity: "0.7" },
                          objectFit: "contain",
                        }}
                        width={150}
                        src={e.url}
                      />

                      <Box
                        className="deleteBox"
                        sx={{
                          position: "absolute",
                          left: "50%",
                          top: "50%",
                          transform: "translate(-50%,-50%)",
                          visibility: "hidden",
                          display: "block",
                          zIndex: "999",
                        }}
                      >
                        <IconButton
                          onClick={() => handleDelete(e.url)}
                          sx={{ "&:hover": { background: "white" } }}
                        >
                          <DeleteIcon color="error" fontSize="large" />
                        </IconButton>
                      </Box>
                    </Box>
                  ))}
              </Box>
            </CardContent>
            <CardActions
              sx={{ flexDirection: "column", alignItems: "flex-end" }}
            >
              <LoadingButton
                loading={load}
                type="submit"
                variant="contained"
                size="large"
              >
                Submit
              </LoadingButton>
            </CardActions>
          </form>
        </Card>
      </Box>
    </Box>
  );
};

export default CreateCampaign;
