import { SxProps } from "@mui/material";

const Style: { [key: string]: SxProps } = {
  StickyHeaderStyle: {
    position: "sticky",
    top: 73,
    background: "#fafbfc",
    py: 2,
    zIndex: 1000,
  },
};
export default Style;
