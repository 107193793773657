import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import HttpService from "../../hooks/Https-services";
import { API_ROUTES, APP_ROUTES } from "../../utils/constants";
import { usePlacesWidget } from "react-google-autocomplete";
import { useNavigate, useParams } from "react-router-dom";
import useCommonDetails from "../../hooks/useCommonDetails";
import { LoadingButton } from "@mui/lab";

const EditCampaigns = () => {
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    reset,
    clearErrors,
    unregister,
    formState: { errors },
  } = useForm();
  const VisuallyHiddenInput = styled("input")`
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    white-space: nowrap;
    width: 1px;
  `;
  const { id } = useParams();
  const [car, setCar] = useState([0]);
  const [images, setImages] = useState<any>([]);
  const commonDetail = useCommonDetails();
  const [load, setLoad] = useState(true);
  const emptyArray = new Array(4).fill(0);
  const navigate = useNavigate();
  const handleCarChange = (event: any) => {
    const {
      target: { value },
    } = event;

    const newValue = value.length > 0 ? value.filter((e: any) => e != 0) : [0];
    setCar(
      // On autofill we get a stringified value.
      typeof newValue === "string" ? newValue.split(",") : newValue
    );
  };

  const handleOpenImage = (e: any) => {
    window.open(e);
  };
  const handleFileChange = async (event: any) => {
    if (!event.target.files[0]) {
      toast.error("Please Select Image");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("advertiseImages", event.target.files[0]);
      formData.append("advertiseId", id as string);
      const res = await HttpService.post(API_ROUTES.AddCampaignImage, formData);
      handleImages(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (e: any) => {
    try {
      const dataBody = {
        image_ids: [e],
        advertiseId: Number(id),
      };
      const res = await HttpService.post(
        API_ROUTES.DeleteCampaignImage,
        dataBody
      );
      handleImages(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const toAddress = (place: any) => {
    const { lat, lng } = place.geometry.location;
    const location = { lat: lat(), lon: lng() };
    return extractAddress(place.address_components, location);
  };
  const extractAddress = (addresses: any[], location: any) => {
    const address = {
      location,
      country: extractAddressData("country", addresses),
      street1: extractAddressStreetData(addresses),
      street2: extractAddressData("sublocality_level_2", addresses),
      city: extractAddressData("locality", addresses),
      state: extractAddressData("administrative_area_level_1", addresses),
      zip: extractAddressData("postal_code", addresses),
    };

    return address;
  };
  const extractAddressData = (name: string, addresses: any[]) =>
    addresses.find((c) => c.types.includes(name))?.long_name || "";

  const extractAddressStreetData = (addresses: any[]) => {
    const street = extractAddressData("sublocality_level_1", addresses);
    if (street) {
      const route = extractAddressData("route", addresses);
      if (route) {
        return `${street} ${route}`;
      }
    }
    return "";
  };
  const checkKeyDown = (e: any) => {
    if (e.key === "Enter") e.preventDefault();
  };
  const AutocompleteAdd = () => {
    const refs = usePlacesWidget<HTMLDivElement>({
      libraries: ["places"],
      options: { types: ["address"] },

      onPlaceSelected: (place: any) => {
        //set state
        let data = toAddress(place);
        setValue("fullLocation", data);
      },
    });

    return (
      <TextField
        fullWidth
        inputRef={refs.ref}
        label="Location"
        variant="outlined"
        autoComplete={"off"}
        size="medium"
        InputLabelProps={{ shrink: true }}
        InputProps={{
          style: { color: "black" },
        }}
        {...register("location", {
          required: "This field is required.",
        })}
        sx={{
          mb: 1,
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "black",
            },
          },
          "& .MuiFormLabel-root": {
            color: "black !important",
          },
        }}
        error={!!errors.location}
        helperText={
          errors.location && (
            <Typography variant="caption" sx={{ color: "red" }}>
              {errors.location.message as any}
            </Typography>
          )
        }
      />
    );
  };
  const handleImages = (data: any) => {
    const temp: any = [];
    console.log(data);
    data.adImages?.map((image: any) => {
      temp.push({
        image: image.image,
        id: image.image_id,
      });
    });
    setImages(temp);
  };
  const getData = async () => {
    try {
      setLoad(true);
      const res = await HttpService.get(
        API_ROUTES.GetCampaigns.replace(":id", "" + id)
      );
      const data = res.data.data;
      setValue(
        "location",
        `${data.location?.street1},${data.location?.city},${data.location?.state},${data.location?.country}`
      );
      setValue("id", data.id);
      setValue("cpm", data.cpm);
      setValue("name", data.name);
      setCar(data.car_type.model);
      handleImages(data);
      setLoad(false);
    } catch (error) {
      setLoad(false);
      console.log(error);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const onSubmit = async (data: any) => {
    try {
      if (!car) {
        toast.error("Please select car type.");
        return;
      }
      setLoad(true);
      const dataBody: any = {
        name: data.name,
        cpm: "" + data.cpm,
        car_type: car,
      };
      const loc = watch("fullLocation");
      if (loc) {
        dataBody.location = loc;
      }
      const res = await HttpService.put(
        API_ROUTES.UpdateCapmaign.replace(":id", "" + id),
        dataBody
      );
      toast.success("Detail Updated sucessfully.");
      setLoad(false);
      navigate(APP_ROUTES.AdvertiserCampaign);
    } catch (error) {
      setLoad(false);
      console.log(error);
    }
  };
  return (
    <Box p={2}>
      <Typography variant="h5" mb={1} fontWeight={500}>
        Update Campaign
      </Typography>
      <Box>
        <Card
          sx={{
            px: { xs: 3, sm: 4 },
            py: { xs: 3, sm: 4 },
            maxWidth: "890px",
            width: { xs: "70%", sm: "80%" },
            borderRadius: "20px",
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <CardContent sx={{ p: "4px !important" }}>
              {!load ? (
                <Grid container spacing={2} mt={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Name"
                      autoComplete="off"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        style: { color: "black" },
                      }}
                      {...register("name", {
                        required: "This field is required.",
                      })}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "black",
                          },
                        },
                        "& .MuiFormLabel-root": {
                          color: "black !important",
                        },
                      }}
                      error={!!errors.name}
                      helperText={
                        errors.name && (
                          <Typography variant="caption" sx={{ color: "red" }}>
                            {errors.name.message as any}
                          </Typography>
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Cpm (cost per mille)"
                      autoComplete="off"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        style: { color: "black" },
                      }}
                      {...register("cpm", {
                        required: "This field is required.",
                      })}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "black",
                          },
                        },
                        "& .MuiFormLabel-root": {
                          color: "black !important",
                        },
                      }}
                      error={!!errors.cpm}
                      helperText={
                        errors.cpm && (
                          <Typography variant="caption" sx={{ color: "red" }}>
                            {errors.cpm.message as any}
                          </Typography>
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Select
                      multiple
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={car}
                      onChange={handleCarChange}
                      size="medium"
                      fullWidth
                    >
                      <MenuItem value={0} sx={{ mt: 0.4 }} disabled>
                        Select Car Type
                      </MenuItem>
                      <MenuItem value={"Sedan"}>Sedan</MenuItem>
                      <MenuItem value={"XUV"}>XUV</MenuItem>
                      <MenuItem value={"Hatchback"}>Hatchback</MenuItem>
                      <MenuItem value={"SUV"}>SUV</MenuItem>
                      <MenuItem value={"Sports"}>Sports</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <AutocompleteAdd />
                  </Grid>
                </Grid>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    width: "100%",
                    mt: 2,
                  }}
                >
                  <Grid container spacing={3} pr={1}>
                    {emptyArray.map((ele: any) => (
                      <Grid item xs={12} sm={6} sx={{ flexGrow: 1 }}>
                        <Skeleton
                          variant="rectangular"
                          height={50}
                          sx={{ maxWidth: "500px" }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              )}
              {!load ? (
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 2,
                    height: "300px",
                    width: "100%",
                    backgroundColor: "#ebf7ff",
                    borderRadius: "0.75rem",
                  }}
                >
                  <AttachmentIcon sx={{ fontSize: "26px" }} />
                  <Typography
                    variant="body1"
                    sx={{ whiteSpace: "nowrap", fontWeight: 500 }}
                  >
                    Add Campaign Images
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{ whiteSpace: "nowrap", fontWeight: 500 }}
                  >
                    (First image will be thumbnail of the campaign)
                  </Typography>
                  <Button
                    component="label"
                    role={undefined}
                    tabIndex={-1}
                    variant="outlined"
                    sx={{ color: "nautral", textTransform: "none", mt: 1 }}
                    size="small"
                  >
                    Upload+
                    <input
                      accept="image/*"
                      hidden
                      id="raised-button-file"
                      onChange={handleFileChange}
                      type="file"
                    />
                    <VisuallyHiddenInput type="file" />
                  </Button>
                </Card>
              ) : (
                <Card
                  sx={{
                    mt: 2,
                    height: "300px",
                    width: "100%",
                  }}
                >
                  <Skeleton variant="rounded" height={300} width={"100%"} />
                </Card>
              )}
              <Box
                sx={{
                  display: "flex",
                  mt: 2,
                  flexWrap: "wrap",
                  gap: 1.5,
                  ml: 0.5,
                }}
              >
                {images &&
                  images.map((e: any) => (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        position: "relative",
                        "&:hover": {
                          ".deleteBox": {
                            visibility: "visible",
                            background: "transparent",
                          },
                        },
                      }}
                    >
                      <Box
                        onClick={() => handleOpenImage(e.image)}
                        component="img"
                        height={"100%"}
                        sx={{
                          cursor: "pointer",
                          "&:hover": { opacity: "0.7" },
                          objectFit: "contain",
                        }}
                        width={150}
                        src={e.image}
                      />

                      <Box
                        className="deleteBox"
                        sx={{
                          position: "absolute",
                          left: "50%",
                          top: "50%",
                          transform: "translate(-50%,-50%)",
                          visibility: "hidden",
                          display: "block",
                          zIndex: "999",
                        }}
                      >
                        <IconButton
                          onClick={() => handleDelete(e.id)}
                          sx={{ "&:hover": { background: "white" } }}
                        >
                          <DeleteIcon color="error" fontSize="large" />
                        </IconButton>
                      </Box>
                    </Box>
                  ))}
              </Box>
            </CardContent>
            {!load && (
              <CardActions
                sx={{ flexDirection: "column", alignItems: "flex-end" }}
              >
                <LoadingButton
                  loading={load}
                  type="submit"
                  variant="contained"
                  size="large"
                >
                  Submit
                </LoadingButton>
              </CardActions>
            )}
          </form>
        </Card>
      </Box>
    </Box>
  );
};

export default EditCampaigns;
