import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";

import persistStore from "redux-persist/es/persistStore";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store from "./store/store";
import { ThemeProvider } from "@mui/material";
import theme from "./style/theme";
import { GoogleMap, LoadScript, Polygon } from "@react-google-maps/api";
const persistor = persistStore(store);
ReactDOM.render(
  <LoadScript
    googleMapsApiKey="AIzaSyCah0hZ-C-UdqxXEs4lGhBHYf7jW3l5GbA" // Replace with your actual API key
    libraries={["places", "visualization", "geometry"]}
  >
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<div>Loading...</div>}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </LoadScript>,
  document.getElementById("root")
);
