import { LoadingButton } from "@mui/lab";
import {
  Box,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Typography,
  Skeleton,
} from "@mui/material";
import React, { useState } from "react";
import { toast } from "react-toastify";
import HttpService from "../../../../hooks/Https-services";
import { API_ROUTES, APP_ROUTES } from "../../../../utils/constants";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const DriverListTable = (props: any) => {
  const navigate = useNavigate();
  const params: any = useParams();
  const { setIsReferes, isReferes, ele, idx, tabs } = props;
  const [isLoad, setIsLoad] = useState(false);
  const handleAccept = async (e: any, status: any) => {
    try {
      const data = {
        status: status,
      };
      setIsLoad(true);
      const res = await HttpService.post(
        API_ROUTES.AcceptDriver.replace(":id", e),
        data
      );
      setIsReferes(!isReferes);
      toast.success("Accept request successfully");
      setIsLoad(false);
    } catch (error) {
      setIsLoad(false);
      console.log(error);
    }
  };
  return (
    <TableRow
      key={idx}
      sx={{
        "&:nth-of-type(odd)": {
          backgroundColor: "#cfebff",
        },
        "&:last-child td, &:last-child th": {
          border: 0,
        },
      }}
      onClick={() =>
        navigate(APP_ROUTES.CampaignDetails.replace(":id", params.id))
      }
    >
      <TableCell align="center">{idx + 1}</TableCell>
      <TableCell>{ele.driver.username}</TableCell>
      <TableCell>{ele.driver.name}</TableCell>
      <TableCell>{ele.driver.email}</TableCell>
      <TableCell>{ele.driver.mobileNo}</TableCell>
      <TableCell>{ele.driver.location.city}</TableCell>
      <TableCell padding="checkbox">
        <Box sx={{ display: "flex", gap: 1, alignItems: "center", my: 1 }}>
          {tabs != 1 && (
            <LoadingButton
              loading={isLoad}
              variant="contained"
              size="small"
              onClick={() => handleAccept(ele.id, 1)}
            >
              Accept
            </LoadingButton>
          )}
          {tabs != 2 && (
            <LoadingButton
              loading={isLoad}
              variant="contained"
              size="small"
              color="error"
              onClick={() => handleAccept(ele.id, 2)}
            >
              Reject
            </LoadingButton>
          )}
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default DriverListTable;
