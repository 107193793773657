import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import temp from "../../assets/images/sliderImg1.png";
import Campaigns from "./Campaigns";
import SearchIcon from "@mui/icons-material/Search";
import { wrap } from "module";
import HttpService from "../../hooks/Https-services";
import { API_ROUTES, APP_ROUTES, status_code } from "../../utils/constants";
import { toast } from "react-toastify";
import useCommonDetails from "../../hooks/useCommonDetails";
import Style from "../../style/Common.style";
import { LoadingButton } from "@mui/lab";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";

const CampaignList = () => {
  const navigate = useNavigate();
  const [tempData, setTempData] = useState([]);
  const [car, setCar] = useState([0]);
  const [loc, setLoc] = useState([0]);
  const [load, setLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [load2, setLoad2] = useState(false);
  const [query, setQuery] = useState({});
  const [hasMore, setHasMore] = useState(true);
  const emptyArray = new Array(6).fill(0);
  const [filter, setFilter] = useState<any>([]);
  const userDetail = useCommonDetails();
  const [page, setPage] = useState(1);

  const handleCarChange = (event: any) => {
    const {
      target: { value },
    } = event;

    const newValue = value.length > 0 ? value.filter((e: any) => e != 0) : [0];
    setCar(
      // On autofill we get a stringified value.
      typeof newValue === "string" ? newValue.split(",") : newValue
    );
  };
  useEffect(() => {
    handleApplyFilter();
  }, [car, loc]);
  const handleNext = () => {
    getCampaign(query, page);
  };
  const handleSearch = (event: any) => {
    const search = event.target.value;
    console.log(search);
    const temp = tempData.filter((ele: any) => {
      if (ele.name.toLowerCase().includes(search.toLowerCase())) return 1;
    });
    setFilter(search ? temp : [...tempData]);
  };
  const handleChangeLocation = (event: any) => {
    const {
      target: { value },
    } = event;

    const newValue = value.length > 0 ? value.filter((e: any) => e != 0) : [0];
    setLoc(
      // On autofill we get a stringified value.
      typeof newValue === "string" ? newValue.split(",") : newValue
    );
  };
  const handleClearFilter = () => {
    setLoad2(true);
    setCar([0]);
    setLoc([0]);
    setQuery({});
    getCampaign({}, 1, 1);
  };
  const handleApplyFilter = () => {
    const query: any = {};
    // if (loc[0] == 0 && car[0] == 0) {
    //   toast.error("Please select a filter");
    //   return;
    // }
    if (loc[0] != 0) {
      query["location.city"] = {
        $in: loc,
      };
    }
    if (car[0] != 0) {
      const temp: any = [];
      car.map((e: any) => {
        temp.push({
          "car_type.model": {
            $like: `%${e}%`,
          },
        });
      });
      query["$or"] = temp;
    }
    setLoad2(true);
    setQuery(query);
    getCampaign(query, 1, 1);
  };
  const getCampaign = async (quer: any, page: any, flag?: any) => {
    try {
      setIsLoading(true);
      const data: any = {
        query: {},
        options: {
          page: page,
          paginate: 9,
        },
        isCountOnly: false,
      };
      if (query) {
        data.query = quer;
      }
      const data2 = {
        query: {
          userId: userDetail.id,
          $or: [
            {
              status: {
                $eq: status_code.request,
              },
            },
            {
              status: {
                $eq: status_code.approved,
              },
            },
          ],
          isDeleted: false,
        },
        options: {
          select: ["advertiseId"],
        },
        isCountOnly: false,
      };
      const res = await HttpService.post(API_ROUTES.CampaignList, data);

      const temp: any = [];
      res.data.data?.map((ele: any) => {
        const name = ele.name;
        const url = ele.adImages[0].image;
        const car_type = ele.car_type.model?.map((ele: any) => ele).join(",");
        const added = ele.numberOfUserApplied;
        const location = ele.location.city;
        const cpm = ele.cpm;
        const id = ele.id;
        temp.push({
          name: name,
          url: url,
          car_type: car_type,
          added: added,
          location: location,
          cpm: cpm,
          id: id,
        });
      });
      if (flag) {
        setFilter(temp);
      } else {
        setFilter((prevFilter: any) => [...prevFilter, ...temp]);
      }
      if (temp.length == 0 || temp.length < 8) {
        setHasMore(false);
      } else {
        setHasMore(true);
        setPage(page + 1);
      }
      setIsLoading(false);
      setLoad(false);
      setLoad2(false);
    } catch (error) {
      setLoad(false);
      setIsLoading(false);
      setLoad2(false);
      console.log(error);
    }
  };
  console.log(filter.length);
  useEffect(() => {
    getCampaign(query, 1);
  }, []);
  return (
    <Box pt={2} pl={2}>
      <Box sx={Style.StickyHeaderStyle}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" mb={1} fontWeight={500}>
            Campaign List
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: 2,
            mb: 2,
          }}
        >
          {/* <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            sx={{ backgroundColor: "#f0f5ff" }}
            onChange={(e) => handleSearch(e)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            size="small"
          />
        </Box> */}
          <Box sx={{ display: "flex", gap: 2 }}>
            <Select
              multiple
              value={car}
              onChange={handleCarChange}
              size="small"
            >
              <MenuItem value={0} sx={{ mt: 0.4 }} disabled>
                Select Car Type
              </MenuItem>
              <MenuItem value={"Sedan"} sx={{ mt: 0.4 }}>
                Sedan
              </MenuItem>
              <MenuItem value={"XUV"} sx={{ mt: 0.4 }}>
                XUV
              </MenuItem>
              <MenuItem value={"Hatchback"} sx={{ mt: 0.4 }}>
                Hatchback
              </MenuItem>
              <MenuItem value={"SUV"} sx={{ mt: 0.4 }}>
                SUV
              </MenuItem>
              <MenuItem value={"Sports"} sx={{ mt: 0.4 }}>
                Sports
              </MenuItem>
            </Select>

            <Select
              multiple
              value={loc}
              onChange={handleChangeLocation}
              size="small"
            >
              <MenuItem value={0} sx={{ mt: 0.4 }} disabled>
                Select Car Location
              </MenuItem>
              <MenuItem value={"Rajkot"} sx={{ mt: 0.4 }}>
                Rajkot
              </MenuItem>
              <MenuItem value={"Ahmedabad"} sx={{ mt: 0.4 }}>
                Ahmedabad
              </MenuItem>
              <MenuItem value={"Junagadh"} sx={{ mt: 0.4 }}>
                Junagadh
              </MenuItem>
              <MenuItem value={"Upleta"} sx={{ mt: 0.4 }}>
                Upleta
              </MenuItem>
            </Select>
          </Box>
          <Box sx={{ display: "flex", gap: 1, mr: 2.5 }}>
            <LoadingButton
              loading={load2}
              variant="outlined"
              onClick={handleClearFilter}
            >
              Clear Filter
            </LoadingButton>
            {/* <Button
              variant="contained"
              onClick={() => navigate(APP_ROUTES.CreateCampaign)}
            >
              Add Campaign
            </Button> */}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          // display: "flex",
          // flexWrap: "wrap",
          // justifyContent: "center",
          // gap: 5,
          overflow: "auto",
          width: "100%",
        }}
      >
        {/* <InfiniteScroll
          dataLength={filter?.length}
          next={handleNext}
          hasMore={hasMore}
          scrollThreshold={0.8}
          height={"100vh"}
          // endMessage={<Typography textAlign="center">The End :)</Typography>}
          loader={
            load &&
            emptyArray.map((ele: any) => (
              <Box my={0.5}>
                <Skeleton variant="rounded" width="100%" height={80} />
              </Box>
            ))
          }
        > */}
        <Grid container spacing={3} pr={1}>
          {!load ? (
            filter.length > 0 ? (
              <>
                {filter?.map((ele: any) => (
                  <Grid item xs={12} sm={6} md={6} lg={4}>
                    <Campaigns
                      url={ele.url}
                      name={ele.name}
                      location={ele.location}
                      cpm={ele.cpm}
                      added={ele.added}
                      car_type={ele.car_type}
                      id={ele.id}
                      query={query}
                      getCampaign={getCampaign}
                    />
                  </Grid>
                ))}
                <Grid item xs={12} sx={{ textAlign: "center" }}>
                  {hasMore && (
                    <LoadingButton
                      loading={isLoading}
                      variant="contained"
                      onClick={handleNext}
                      size="small"
                      sx={{ px: 5 }}
                    >
                      Load More
                    </LoadingButton>
                  )}
                </Grid>
              </>
            ) : (
              <Grid item xs={12}>
                <Typography variant="body1" textAlign="center">
                  No data found on this filte!
                </Typography>
              </Grid>
            )
          ) : (
            <Box sx={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
              <Grid container spacing={3} pr={1}>
                {emptyArray.map((ele: any) => (
                  <Grid item xs={12} sm={6} md={6} lg={4} sx={{ flexGrow: 1 }}>
                    <Skeleton
                      variant="rectangular"
                      height={400}
                      sx={{ maxWidth: "500px" }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
        </Grid>
        {/* </InfiniteScroll> */}
        {/* ) : (
          <Grid container spacing={3} pr={1}>
            <Grid item xs={12}>
              <Typography variant="body1" textAlign="center">
                No data found on this filte!
              </Typography>
            </Grid>
          </Grid>
        )} */}
      </Box>
    </Box>
  );
};

export default CampaignList;
