import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import HttpService from "../../hooks/Https-services";
import { API_ROUTES } from "../../utils/constants";
import useCommonDetails from "../../hooks/useCommonDetails";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteDialog from "../DeleteDialog/DeleteDialog";

const DriverProfile = () => {
  const { t, i18n } = useTranslation();
  const [images, setImages] = useState<any>([]);
  const [dialogImg, setDialogImg] = useState("");
  const commonDetail = useCommonDetails();
  const [car, setCar] = useState("");
  const [open, setOpen] = React.useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [load, setLoad] = useState(false);
  const [load2, setLoad2] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = (e: any) => {
    setOpen(true);
    setDialogImg(e);
  };

  const handleCarChange = (event: any) => {
    setCar(event.target.value as string);
  };
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    reset,
    clearErrors,
    unregister,
    formState: { errors },
  } = useForm();
  const VisuallyHiddenInput = styled("input")`
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    white-space: nowrap;
    width: 1px;
  `;
  const emptyArray = new Array(4).fill(0);
  const handleImages = (data: any) => {
    const temp: any = [];
    data.vehicleImages.map((image: any) => {
      temp.push({
        image: image.image,
        id: image.image_id,
      });
    });
    setImages(temp);
  };
  const handleFileChange = async (event: any) => {
    if (!event.target.files[0]) {
      toast.error("Please Select Image");
      return;
    }
    try {
      const formData = new FormData();
      formData.append("vehicleImages", event.target.files[0]);
      const res = await HttpService.post(API_ROUTES.AddImage, formData);
      handleImages(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenImage = (e: any) => {
    window.open(e);
  };

  const getDriver = async () => {
    try {
      setLoad2(true);
      const res = await HttpService.get(
        API_ROUTES.GetOnboard.replace(":id", commonDetail.id)
      );
      setValue("carModel", res.data.data.carModel);
      setValue("age", res.data.data.age);
      setValue("companyName", res.data.data.companyName);
      setValue("aboutYou", res.data.data.description);
      setValue("numberPlate", res.data.data.numberPlate);

      setCar(res.data.data.car_type);
      handleImages(res.data.data);
      setLoad2(false);
    } catch (error) {
      setLoad2(false);
      console.log(error);
    }
  };
  useEffect(() => {
    getDriver();
  }, []);

  let imgId = useRef();
  const ImgDelete = async (type: boolean) => {
    if (type) {
      try {
        const dataBody = {
          image_ids: [imgId.current],
        };
        const res = await HttpService.post(API_ROUTES.DeleteImage, dataBody);
        handleImages(res.data.data);
      } catch (error) {
        console.log(error);
      }
      setDialogOpen(false);
    } else {
      setDialogOpen(false);
    }
  };

  const handleDelete = async (e: any) => {
    imgId.current = e;
    setDialogOpen(true);
  };

  const onSubmit = async (data: any) => {
    try {
      setLoad(true);
      const dataBody = {
        carModel: data.carModel,
        age: data.age,
        companyName: data.companyName,
        car_type: car,
        numberPlate: data.numberPlate,
        description: data.aboutYou,
      };
      const res = await HttpService.patch(
        API_ROUTES.UpdateOnboard.replace(":id", commonDetail.id),
        dataBody
      );
      setLoad(false);
      toast.success("Profile updated successfully");
    } catch (error) {
      console.log(error);
      setLoad(false);
    }
  };
  return (
    <>
      <Box p={2}>
        <Typography variant="h5" mb={1} fontWeight={500}>
          {t("profile.profile")}
        </Typography>
        <Box>
          <Card
            sx={{
              px: { xs: 3, sm: 4 },
              maxWidth: "890px",
              width: { xs: "100%", sm: "80%" },
              borderRadius: "20px",
              pb: 2,
            }}
          >
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <img src={dialogImg} />
            </Dialog>
            <form onSubmit={handleSubmit(onSubmit)}>
              <CardContent sx={{ p: "4px !important" }}>
                {!load2 ? (
                  <Grid container spacing={2} mt={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label={t("profile.carcompany")}
                        autoComplete="off"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          style: { color: "black" },
                        }}
                        {...register("companyName", {
                          required: "This field is required.",
                        })}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "black",
                            },
                          },
                        }}
                        error={!!errors.companyName}
                        helperText={
                          errors.companyName && (
                            <Typography variant="caption" sx={{ color: "red" }}>
                              {errors.companyName.message as any}
                            </Typography>
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label={t("profile.carmodel")}
                        autoComplete="off"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          style: { color: "black" },
                        }}
                        {...register("carModel", {
                          required: "This field is required.",
                        })}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "black",
                            },
                          },
                        }}
                        error={!!errors.carModel}
                        helperText={
                          errors.carModel && (
                            <Typography variant="caption" sx={{ color: "red" }}>
                              {errors.carModel.message as any}
                            </Typography>
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          {t("profile.cartype")}
                        </InputLabel>

                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={car}
                          onChange={handleCarChange}
                          size="medium"
                          label={t("profile.cartype")}
                          fullWidth
                        >
                          <MenuItem value={"Sedan"}>Sedan</MenuItem>
                          <MenuItem value={"XUV"}>XUV</MenuItem>
                          <MenuItem value={"Hatchback"}>Hatchback</MenuItem>
                          <MenuItem value={"SUV"}>SUV</MenuItem>
                          <MenuItem value={"Sports"}>Sports</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label={t("profile.age")}
                        autoComplete="off"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          style: { color: "black" },
                        }}
                        {...register("age", {
                          required: "Age is required.",
                        })}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "black",
                            },
                          },
                        }}
                        error={!!errors.age}
                        helperText={
                          errors.age && (
                            <Typography variant="caption" sx={{ color: "red" }}>
                              {errors.age.message as any}
                            </Typography>
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label={t("profile.carnumberplate")}
                        autoComplete="off"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          style: { color: "black" },
                        }}
                        {...register("numberPlate", {
                          required: "This field  is required.",
                        })}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "black",
                            },
                          },
                        }}
                        placeholder="ABC-123"
                        error={!!errors.numberPlate}
                        helperText={
                          errors.numberPlate && (
                            <Typography variant="caption" sx={{ color: "red" }}>
                              {errors.numberPlate.message as any}
                            </Typography>
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        rows={2}
                        multiline
                        label={t("profile.aboutyou")}
                        autoComplete="off"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          style: { color: "black" },
                        }}
                        {...register("aboutYou", {
                          required: "This field is required.",
                        })}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "black",
                            },
                          },
                        }}
                        placeholder="User  routinely navigates through [City] via [Specific Way/Road/Route] in their usual driving area."
                        error={!!errors.aboutYou}
                        helperText={
                          errors.aboutYou && (
                            <Typography variant="caption" sx={{ color: "red" }}>
                              {errors.aboutYou.message as any}
                            </Typography>
                          )
                        }
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      width: "100%",
                      mt: 2,
                    }}
                  >
                    <Grid container spacing={3} pr={1}>
                      {emptyArray.map((ele: any) => (
                        <Grid item xs={12} sm={6} sx={{ flexGrow: 1 }}>
                          <Skeleton
                            variant="rectangular"
                            height={50}
                            sx={{ maxWidth: "500px" }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                )}
                {!load2 ? (
                  <Card
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      mt: 2,
                      height: "300px",
                      width: "100%",
                      backgroundColor: "#ebf7ff",
                      borderRadius: "0.75rem",
                    }}
                  >
                    <AttachmentIcon sx={{ fontSize: "26px" }} />
                    <Typography
                      variant="body1"
                      color="gray"
                      sx={{ whiteSpace: "nowrap", fontWeight: 500 }}
                    >
                      {t("profile.addcarimage")}
                    </Typography>
                    <Button
                      component="label"
                      role={undefined}
                      tabIndex={-1}
                      variant="outlined"
                      sx={{ color: "nautral", textTransform: "none", mt: 1 }}
                      size="small"
                    >
                      {t("profile.upload")}
                      <input
                        accept="image/*"
                        hidden
                        id="raised-button-file"
                        onChange={handleFileChange}
                        type="file"
                      />
                      <VisuallyHiddenInput type="file" />
                    </Button>
                  </Card>
                ) : (
                  <Card
                    sx={{
                      mt: 2,
                      height: "300px",
                      width: "100%",
                    }}
                  >
                    <Skeleton variant="rounded" height={300} width={"100%"} />
                  </Card>
                )}
                <Box
                  sx={{
                    display: "flex",
                    mt: 2,
                    flexWrap: "wrap",
                    gap: 1.5,
                    ml: 0.5,
                  }}
                >
                  {images &&
                    images.map((e: any) => (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          position: "relative",
                          "&:hover": {
                            ".deleteBox": {
                              visibility: "visible",
                              background: "transparent",
                            },
                          },
                        }}
                      >
                        <Box
                          onClick={() => handleClickOpen(e.image)}
                          component="img"
                          height={"100%"}
                          sx={{
                            cursor: "pointer",
                            "&:hover": { opacity: "0.7" },
                          }}
                          width={150}
                          src={e.image}
                        />
                        <Box
                          className="deleteBox"
                          sx={{
                            position: "absolute",
                            left: "50%",
                            top: "50%",
                            transform: "translate(-50%,-50%)",
                            visibility: "hidden",
                            display: "block",
                            zIndex: "999",
                          }}
                        >
                          <IconButton
                            onClick={() => handleDelete(e.id)}
                            sx={{ "&:hover": { background: "white" } }}
                          >
                            <DeleteIcon color="error" fontSize="large" />
                          </IconButton>
                        </Box>
                      </Box>
                    ))}
                </Box>
              </CardContent>
              {!load2 && (
                <CardActions
                  sx={{ flexDirection: "column", alignItems: "flex-end" }}
                >
                  <LoadingButton
                    loading={load}
                    type="submit"
                    variant="contained"
                    size="large"
                  >
                    {t("profile.submit")}
                  </LoadingButton>
                </CardActions>
              )}
            </form>
          </Card>
        </Box>
      </Box>

      {dialogOpen && <DeleteDialog open={dialogOpen} onDialog={ImgDelete} />}
    </>
  );
};

export default DriverProfile;
