import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { useForm } from "react-hook-form";
import { usePlacesWidget } from "react-google-autocomplete";
import "./style.css";
import HttpService from "../../../hooks/Https-services";
import { API_ROUTES } from "../../../utils/constants";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";

const AdvertiserList = (props: any) => {
  const [load, setLoad] = useState(false);
  const [open, setOpen] = useState(false);
  const { data, getAdvetiser, row, page, total } = props;
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    reset,
    clearErrors,
    unregister,
    formState: { errors },
  } = useForm();
  const handleUpdate = (e: any) => {
    setOpen(true);
    console.log(e);
    setValue("id", e.id);
    setValue("name", e.name);
    setValue("username", e.username);
    setValue("number", e.mobileNo);
    setValue("stree1", e.location?.street1);
    setValue("stree2", e.location?.street2);
    setValue("city", e.location?.city);
    setValue("state", e.location?.state);
    setValue("country", e.location?.contry);
    setValue("company", e.companyName);
    setValue(
      "location",
      `${e.location?.street1},${e.location?.city},${e.location?.state},${e.location?.country}`
    );
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    getAdvetiser(newPage + 1, row);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const r = parseInt(event.target.value, 10);
    getAdvetiser(1, r);
    // if (total / row >= page) {
    // }
  };

  const toAddress = (place: any) => {
    const { lat, lng } = place.geometry.location;
    const location = { lat: lat(), lon: lng() };
    return extractAddress(place.address_components, location);
  };
  const refs = usePlacesWidget<HTMLDivElement>({
    libraries: ["places"],
    options: { types: ["address"] },

    onPlaceSelected: (place: any) => {
      //set state
      let data = toAddress(place);
      setValue("fullLocation", data);
    },
  });
  const extractAddress = (addresses: any[], location: any) => {
    const address = {
      location,
      country: extractAddressData("country", addresses),
      street1: extractAddressStreetData(addresses),
      street2: extractAddressData("sublocality_level_2", addresses),
      city: extractAddressData("locality", addresses),
      state: extractAddressData("administrative_area_level_1", addresses),
      zip: extractAddressData("postal_code", addresses),
    };

    return address;
  };
  const extractAddressData = (name: string, addresses: any[]) =>
    addresses.find((c) => c.types.includes(name))?.long_name || "";

  const extractAddressStreetData = (addresses: any[]) => {
    const street = extractAddressData("sublocality_level_1", addresses);
    if (street) {
      const route = extractAddressData("route", addresses);
      if (route) {
        return `${street} ${route}`;
      }
    }
    return "";
  };
  const checkKeyDown = (e: any) => {
    if (e.key === "Enter") e.preventDefault();
  };
  const onSubmit = async (data: any) => {
    try {
      setLoad(true);
      const dataBody: any = {
        name: data.name,
        mobileNo: data.number,
        username: data.username,
        companyName: data.company,
      };
      const loc = watch("fullLocation");
      if (loc) {
        dataBody.location = loc;
      }
      const res = await HttpService.patch(
        API_ROUTES.UpdateAdvertiser.replace(":id", watch("id")),
        dataBody
      );
      if (data.password) {
        const body = {
          password: data.password,
        };
        const res = await HttpService.put(
          API_ROUTES.ChangeAdvertiserPassword.replace(":id", watch("id")),
          body
        );
      }
      toast.success("Advertiser updated successfully");
      setOpen(false);
      getAdvetiser(page, row);
      setLoad(false);
    } catch (error) {
      setLoad(false);
      console.log(error);
    }
  };
  const AutocompleteAdd = () => {
    const refs = usePlacesWidget<HTMLDivElement>({
      libraries: ["places"],
      options: { types: ["address"] },

      onPlaceSelected: (place: any) => {
        //set state
        console.log(place);
        let data = toAddress(place);
        setValue("fullLocation", data);
      },
    });

    return (
      <TextField
        fullWidth
        inputRef={refs.ref}
        label="Location"
        variant="outlined"
        autoComplete={"off"}
        size="medium"
        InputLabelProps={{ shrink: true }}
        InputProps={{
          style: { color: "black" },
        }}
        {...register("location", {
          required: "This field is required.",
        })}
        sx={{
          mt: 1,
          mb: 1,
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "black",
            },
          },
          "& .MuiFormLabel-root": {
            color: "black !important",
          },
        }}
        error={!!errors.location}
        helperText={
          errors.location && (
            <Typography variant="caption" sx={{ color: "red" }}>
              {errors.location.message as any}
            </Typography>
          )
        }
      />
    );
  };
  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead
            sx={{
              ".MuiTableCell-root": {
                px: "5px",
              },
            }}
          >
            <TableRow>
              <TableCell align="center">No</TableCell>
              <TableCell>User Name</TableCell>
              <TableCell>Full Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Location</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data?.data?.map((ele: any, idx: any) => (
              <TableRow
                key={idx}
                sx={{
                  "&:nth-of-type(odd)": {
                    backgroundColor: "#cfebff",
                  },
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                }}
              >
                <TableCell align="center">{idx + 1}</TableCell>
                <TableCell>{ele.username}</TableCell>
                <TableCell>{ele.name}</TableCell>
                <TableCell>{ele.email}</TableCell>
                <TableCell>{ele.mobileNo}</TableCell>
                <TableCell>{ele.location.city}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleUpdate(ele)}>
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          rowsPerPage={row}
          count={total}
          page={page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      <Dialog
        onClose={() => {
          setOpen(false);
          reset();
        }}
        open={open}
        sx={{
          "& .MuiDialog-paper": {
            backgroundColor: "transparent", // Set the background color to transparent
            boxShadow: "none", // Remove the box shadow
            borderRadius: "0", // Remove the border radius
          },
        }}
      >
        <Card
          sx={{
            px: { xs: 3, sm: 4 },
            py: { xs: 3, sm: 4 },
            maxWidth: "890px",
            borderRadius: "20px",
          }}
        >
          <form
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={(e) => checkKeyDown(e)}
          >
            <CardContent sx={{ p: "4px !important" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "ceneter",
                }}
              >
                <Typography variant="h6">Update Advertiser</Typography>
                <IconButton
                  onClick={() => {
                    setOpen(false);
                    reset();
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>

              <Grid container spacing={2} mt={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Username"
                    variant="outlined"
                    size="medium"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      style: { color: "black" },
                    }}
                    {...register("username", {
                      required: "This field is required.",
                    })}
                    sx={{
                      mt: 1,
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "black",
                        },
                      },
                      "& .MuiFormLabel-root": {
                        color: "black !important",
                      },
                    }}
                    error={!!errors.username}
                    helperText={
                      errors.username && (
                        <Typography variant="caption" sx={{ color: "red" }}>
                          {errors.username.message as any}
                        </Typography>
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Name"
                    variant="outlined"
                    size="medium"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      style: { color: "black" },
                    }}
                    {...register("name", {
                      required: "This field is required.",
                    })}
                    sx={{
                      mt: 1,
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "black",
                        },
                      },
                      "& .MuiFormLabel-root": {
                        color: "black !important",
                      },
                    }}
                    error={!!errors.name}
                    helperText={
                      errors.name && (
                        <Typography variant="caption" sx={{ color: "red" }}>
                          {errors.name.message as any}
                        </Typography>
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Phone Number"
                    variant="outlined"
                    size="medium"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      style: { color: "black" },
                    }}
                    {...register("number", {
                      required: "This field is required.",
                      pattern: {
                        value: /^\d{10}$/,
                        message: "Please enter a valid number",
                      },
                    })}
                    sx={{
                      mt: 1,
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "black",
                        },
                      },
                      "& .MuiFormLabel-root": {
                        color: "black !important",
                      },
                    }}
                    error={!!errors.number}
                    helperText={
                      errors.number && (
                        <Typography variant="caption" sx={{ color: "red" }}>
                          {errors.number.message as any}
                        </Typography>
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AutocompleteAdd />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="New Password"
                    type="password"
                    size="medium"
                    variant="outlined"
                    placeholder="Password Update"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      style: { color: "black" },
                    }}
                    {...register("password", {
                      validate: {
                        minLength: (value) => {
                          if (!value) return; // If no value, skip validation
                          return (
                            value.length >= 8 ||
                            "Password must be at least 8 characters long."
                          );
                        },
                      },
                    })}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "black",
                        },
                      },
                      "& .MuiFormLabel-root": {
                        color: "black !important",
                      },
                    }}
                    error={!!errors.password}
                    helperText={
                      errors.password && (
                        <Typography variant="caption" sx={{ color: "red" }}>
                          {errors.password.message as any}
                        </Typography>
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Company Name"
                    variant="outlined"
                    size="medium"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      style: { color: "black" },
                    }}
                    {...register("company", {
                      required: "This field is required.",
                    })}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "black",
                        },
                      },
                    }}
                    error={!!errors.company}
                    helperText={
                      errors.company && (
                        <Typography variant="caption" sx={{ color: "red" }}>
                          {errors.company.message as any}
                        </Typography>
                      )
                    }
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions
              sx={{ flexDirection: "column", alignItems: "flex-end" }}
            >
              <LoadingButton
                loading={load}
                type="submit"
                variant="contained"
                size="large"
              >
                Submit
              </LoadingButton>
            </CardActions>
          </form>
        </Card>
      </Dialog>
    </Box>
  );
};

export default AdvertiserList;
