import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import HttpService from "../../hooks/Https-services";
import { API_ROUTES } from "../../utils/constants";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import commonDetailSlice from "../../store/reducers/commonReducer";

const Onboard = () => {
  const [images, setImages] = useState<any>([]);
  const [car, setCar] = useState("");
  const [load, setLoad] = useState(false);

  const handleCarChange = (event: any) => {
    setCar(event.target.value as string);
  };
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    reset,
    clearErrors,
    unregister,
    formState: { errors },
  } = useForm();
  const VisuallyHiddenInput = styled("input")`
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    white-space: nowrap;
    width: 1px;
  `;
  const dispatch = useDispatch();
  async function getEntries(data: any) {
    dispatch(commonDetailSlice.actions.removecommonDetails());
    dispatch(commonDetailSlice.actions.setcommonDetails(data));
  }
  const handleFileChange = async (event: any) => {
    if (!event.target.files[0]) {
      toast.error("Please Select Image");
      return;
    }

    const image = {
      file: event.target.files[0],
      url: URL.createObjectURL(event.target.files[0]),
    };
    setImages((prevImages: any) => [...prevImages, image]);
  };

  const handleOpenImage = (e: any) => {
    window.open(e);
  };

  const handleDelete = (e: any) => {
    const temp = images.filter((ele: any) => ele.url != e);
    setImages(temp);
  };

  const onSubmit = async (data: any) => {
    try {
      if (!car) {
        toast.error("Please select car type.");
        return;
      }
      setLoad(true);
      const files: any = [];
      images.map((e: any) => files.push(e.file));
      const formData = new FormData();
      formData.append("carModel", data.carModel);
      formData.append("age", data.age);
      formData.append("companyName", data.companyName);
      formData.append("car_type", car);
      formData.append("numberPlate", data.numberPlate);
      formData.append("description", data.aboutYou);
      files.map((e: any, i: any) => {
        formData.append("vehicleImages", files[i]);
      });
      const res = await HttpService.post(API_ROUTES.Onboard, formData);
      const res2 = await HttpService.get(API_ROUTES.GetDetial);
      getEntries(res2.data.data);
      setLoad(false);
      toast.success("Onbaord successfully");
    } catch (error) {
      setLoad(false);
      console.log(error);
    }
  };
  return (
    <Box p={2}>
      <Typography variant="h5" mb={1} fontWeight={500}>
        Onboarding
      </Typography>
      <Box>
        <Card
          sx={{
            px: { xs: 3, sm: 4 },
            maxWidth: "890px",
            width: { xs: "100%", sm: "80%" },
            borderRadius: "20px",
            pb: 2,
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <CardContent sx={{ p: "4px !important" }}>
              <Grid container spacing={2} mt={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Car Company"
                    autoComplete="off"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      style: { color: "black" },
                    }}
                    {...register("companyName", {
                      required: "This field is required.",
                    })}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "black",
                        },
                      },
                    }}
                    placeholder="Tata"
                    error={!!errors.companyName}
                    helperText={
                      errors.companyName && (
                        <Typography variant="caption" sx={{ color: "red" }}>
                          {errors.companyName.message as any}
                        </Typography>
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Car Model"
                    autoComplete="off"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      style: { color: "black" },
                    }}
                    {...register("carModel", {
                      required: "This field is required.",
                    })}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "black",
                        },
                      },
                    }}
                    placeholder="Nexon"
                    error={!!errors.carModel}
                    helperText={
                      errors.carModel && (
                        <Typography variant="caption" sx={{ color: "red" }}>
                          {errors.carModel.message as any}
                        </Typography>
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth required>
                    <InputLabel id="demo-simple-select-label">
                      Car Type
                    </InputLabel>

                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={car}
                      onChange={handleCarChange}
                      size="medium"
                      label="Car Type"
                      fullWidth
                    >
                      <MenuItem value={"Sedan"}>Sedan</MenuItem>
                      <MenuItem value={"XUV"}>XUV</MenuItem>
                      <MenuItem value={"Hatchback"}>Hatchback</MenuItem>
                      <MenuItem value={"SUV"}>SUV</MenuItem>
                      <MenuItem value={"Sports"}>Sports</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Age"
                    autoComplete="off"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      style: { color: "black" },
                    }}
                    {...register("age", {
                      required: "This field is required.",
                    })}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "black",
                        },
                      },
                    }}
                    placeholder="21"
                    error={!!errors.age}
                    helperText={
                      errors.age && (
                        <Typography variant="caption" sx={{ color: "red" }}>
                          {errors.age.message as any}
                        </Typography>
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Car Number Plate "
                    autoComplete="off"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      style: { color: "black" },
                    }}
                    {...register("numberPlate", {
                      required: "This field  is required.",
                    })}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "black",
                        },
                      },
                    }}
                    placeholder="ABC-123"
                    error={!!errors.numberPlate}
                    helperText={
                      errors.numberPlate && (
                        <Typography variant="caption" sx={{ color: "red" }}>
                          {errors.numberPlate.message as any}
                        </Typography>
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    rows={2}
                    multiline
                    maxRows={3}
                    label="About You"
                    autoComplete="off"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      style: { color: "black" },
                    }}
                    {...register("aboutYou", {
                      required: "This field is required.",
                    })}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "black",
                        },
                      },
                    }}
                    placeholder="User  routinely navigates through [City] via [Specific Way/Road/Route] in their usual driving area."
                    error={!!errors.aboutYou}
                    helperText={
                      errors.aboutYou && (
                        <Typography variant="caption" sx={{ color: "red" }}>
                          {errors.aboutYou.message as any}
                        </Typography>
                      )
                    }
                  />
                </Grid>
              </Grid>
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 2,
                  height: "300px",
                  width: "100%",
                  backgroundColor: "#ebf7ff",
                  borderRadius: "0.75rem",
                }}
              >
                <AttachmentIcon sx={{ fontSize: "26px" }} />
                <Typography
                  variant="body1"
                  sx={{ whiteSpace: "nowrap", fontWeight: 500 }}
                >
                  Add Car Images
                </Typography>
                <Button
                  component="label"
                  role={undefined}
                  tabIndex={-1}
                  variant="outlined"
                  sx={{ color: "nautral", textTransform: "none", mt: 1 }}
                  size="small"
                >
                  Upload+
                  <input
                    accept="image/*"
                    hidden
                    id="raised-button-file"
                    onChange={handleFileChange}
                    type="file"
                  />
                  <VisuallyHiddenInput type="file" />
                </Button>
              </Card>
              <Box
                sx={{
                  display: "flex",
                  mt: 2,
                  flexWrap: "wrap",
                  gap: 1.5,
                  ml: 0.5,
                }}
              >
                {images &&
                  images.map((e: any) => (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        position: "relative",
                        "&:hover": {
                          ".deleteBox": {
                            visibility: "visible",
                            background: "transparent",
                          },
                        },
                      }}
                    >
                      <Box
                        onClick={() => handleOpenImage(e.url)}
                        component="img"
                        height={"100%"}
                        sx={{
                          cursor: "pointer",
                          "&:hover": { opacity: "0.7" },
                          objectFit: "contain",
                        }}
                        width={150}
                        src={e.url}
                      />

                      <Box
                        className="deleteBox"
                        sx={{
                          position: "absolute",
                          left: "50%",
                          top: "50%",
                          transform: "translate(-50%,-50%)",
                          visibility: "hidden",
                          display: "block",
                          zIndex: "999",
                        }}
                      >
                        <IconButton
                          onClick={() => handleDelete(e.url)}
                          sx={{ "&:hover": { background: "white" } }}
                        >
                          <DeleteIcon color="error" fontSize="large" />
                        </IconButton>
                      </Box>
                    </Box>
                  ))}
              </Box>
            </CardContent>
            <CardActions
              sx={{ flexDirection: "column", alignItems: "flex-end" }}
            >
              <LoadingButton
                loading={load}
                type="submit"
                variant="contained"
                size="large"
              >
                Submit
              </LoadingButton>
            </CardActions>
          </form>
        </Card>
      </Box>
    </Box>
  );
};

export default Onboard;
