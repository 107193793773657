import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import HttpService from "../../hooks/Https-services";
import { API_ROUTES } from "../../utils/constants";
import useCommonDetails from "../../hooks/useCommonDetails";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { profile } from "console";

const Profile = () => {
  const { t, i18n } = useTranslation();

  const userData = useCommonDetails();

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    reset,
    clearErrors,
    unregister,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    setValue("name", userData.name);
    setValue("mobileNo", userData.mobileNo);
    setValue("email", userData.email);
    setValue("userName", userData.username);
  }, []);

  const onSubmit = async (data: any) => {
    // try {
    //   setLoad(true);
    //   const dataBody = {
    //     carModel: data.carModel,
    //     age: data.age,
    //     companyName: data.companyName,
    //     car_type: car,
    //     numberPlate: data.numberPlate,
    //     description: data.aboutYou,
    //   };
    //   const res = await HttpService.patch(
    //     API_ROUTES.UpdateOnboard.replace(":id", commonDetail.id),
    //     dataBody
    //   );
    //   setLoad(false);
    //   toast.success("Profile updated successfully");
    // } catch (error) {
    //   console.log(error);
    //   setLoad(false);
    // }
  };
  return (
    <Box p={2}>
      <Typography variant="h5" mb={1} fontWeight={500}>
        Profile
      </Typography>
      <Box>
        <Card
          sx={{
            px: { xs: 3, sm: 4 },
            maxWidth: "890px",
            width: { xs: "100%", sm: "80%" },
            borderRadius: "20px",
            pb: 2,
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <CardContent sx={{ p: "4px !important" }}>
              <Grid container spacing={2} mt={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    disabled
                    label="Name"
                    autoComplete="off"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      style: { color: "black" },
                    }}
                    {...register("name", {
                      required: "This field is required.",
                    })}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "black",
                        },
                      },
                    }}
                    error={!!errors.name}
                    helperText={
                      errors.name && (
                        <Typography variant="caption" sx={{ color: "red" }}>
                          {errors.name.message as any}
                        </Typography>
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    disabled
                    label="Email"
                    autoComplete="off"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      style: { color: "black" },
                    }}
                    {...register("email", {
                      required: "This field is required.",
                    })}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "black",
                        },
                      },
                    }}
                    error={!!errors.email}
                    helperText={
                      errors.email && (
                        <Typography variant="caption" sx={{ color: "red" }}>
                          {errors.email.message as any}
                        </Typography>
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    disabled
                    label="Mobile No"
                    autoComplete="off"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      style: { color: "black" },
                    }}
                    {...register("mobileNo", {
                      required: "This field is required.",
                    })}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "black",
                        },
                      },
                    }}
                    error={!!errors.mobileNo}
                    helperText={
                      errors.mobileNo && (
                        <Typography variant="caption" sx={{ color: "red" }}>
                          {errors.mobileNo.message as any}
                        </Typography>
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    disabled
                    label="UserName"
                    autoComplete="off"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      style: { color: "black" },
                    }}
                    {...register("userName", {
                      required: "This field is required.",
                    })}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "black",
                        },
                      },
                    }}
                    error={!!errors.userName}
                    helperText={
                      errors.userName && (
                        <Typography variant="caption" sx={{ color: "red" }}>
                          {errors.userName.message as any}
                        </Typography>
                      )
                    }
                  />
                </Grid>
              </Grid>
            </CardContent>
          </form>
        </Card>
      </Box>
    </Box>
  );
};

export default Profile;
