import {
  Box,
  List,
  ListItem,
  Typography,
  MenuItem,
  ListItemIcon,
  ListItemText,
  MenuList,
  Collapse,
  Button,
  Drawer,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import AppRegistrationOutlinedIcon from "@mui/icons-material/AppRegistrationOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import AddchartOutlinedIcon from "@mui/icons-material/AddchartOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

import { useLocation, useNavigate } from "react-router-dom";
import ExploreOutlinedIcon from "@mui/icons-material/ExploreOutlined";
import { API_ROUTES, APP_ROUTES, USER_ROLE } from "../../utils/constants";
import HttpService from "../../hooks/Https-services";
import useCommonDetails from "../../hooks/useCommonDetails";
import MenuIcon from "@mui/icons-material/Menu";
import { toast } from "react-toastify";

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const logo = require("../../assets/images/icon.png");
  const commonDetail = useCommonDetails();
  const [menuOpen, setMenuOpen] = useState<any>({
    profile: false,
    ecomm: false,
    project: false,
    account: false,
    blog: false,
    corp: false,
  });
  const [selectOp, setSelectOp] = useState(location.pathname);

  useEffect(() => {
    setSelectOp(location.pathname);
  }, [location.pathname]);

  const handleSelect = (e: any) => {
    setSelectOp(e);
    navigate(e);
  };
  const menuClick = (name: any) => {
    const item = menuOpen;
    item[name] = !item[name];

    setMenuOpen({ ...menuOpen, ...item });
  };
  const Logout = async () => {
    try {
      localStorage.removeItem("auth");
      navigate(APP_ROUTES.Landing);
      toast.success("Logout sucessfully");
    } catch (err) {
      console.log(err);
    }
  };
  const style = {
    "&.Mui-selected": {
      bgcolor: "#0096FF",
      color: "white",
      "&:hover": {
        bgcolor: "#0096FF",
      },
      borderRadius: "4px",
    },
    borderRadius: "4px",
  };
  return (
    <Box>
      <Box
        sx={{
          minHeight: "100vh",
          display: { xs: "none", md: "flex" },
          borderRight: "1px solid lightgray",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            p: "20px 24px",
            flexGrow: 1,
            gap: 4,
          }}
        >
          <Box sx={{ margin: "0 auto" }}>
            <Box src={logo} component="img" />
          </Box>
          <Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 0.6 }}>
              <MenuItem
                onClick={() => handleSelect(APP_ROUTES.Dashboard)}
                selected={selectOp == APP_ROUTES.Dashboard}
                sx={{ ...style }}
              >
                <HomeOutlinedIcon />
                <ListItemText sx={{ ml: 1 }}>Home</ListItemText>
              </MenuItem>

              {commonDetail.userType == USER_ROLE.DRIVER && (
                <>
                  {!commonDetail.isDriverOnBoard && (
                    <MenuItem
                      onClick={() => handleSelect(APP_ROUTES.Onboard)}
                      selected={selectOp == APP_ROUTES.Onboard}
                      sx={{ ...style }}
                    >
                      <AppRegistrationOutlinedIcon />
                      <ListItemText sx={{ ml: 1 }}>OnBoard</ListItemText>
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={() => handleSelect(APP_ROUTES.DriverProfile)}
                    selected={selectOp == APP_ROUTES.DriverProfile}
                    sx={{ ...style }}
                  >
                    <PersonOutlineOutlinedIcon />
                    <ListItemText sx={{ ml: 1 }}>Car Profile</ListItemText>
                  </MenuItem>

                  <MenuItem
                    onClick={() => handleSelect(APP_ROUTES.CampaignsList)}
                    selected={selectOp == APP_ROUTES.CampaignsList}
                    sx={{ ...style }}
                  >
                    <CampaignOutlinedIcon />
                    <ListItemText sx={{ ml: 1 }}>Campaign List</ListItemText>
                  </MenuItem>

                  <MenuItem
                    onClick={() => handleSelect(APP_ROUTES.MyCampaign)}
                    selected={selectOp == APP_ROUTES.MyCampaign}
                    sx={{ ...style }}
                  >
                    <AssignmentTurnedInOutlinedIcon />
                    <ListItemText sx={{ ml: 1 }}>My Campaigns</ListItemText>
                  </MenuItem>

                  <MenuItem
                    onClick={() => handleSelect(APP_ROUTES.DriverContract)}
                    selected={selectOp == APP_ROUTES.DriverContract}
                    sx={{ ...style }}
                  >
                    <AssignmentOutlinedIcon />
                    <ListItemText sx={{ ml: 1 }}>Contract</ListItemText>
                  </MenuItem>
                </>
              )}

              {commonDetail.userType == USER_ROLE.ADVERTISER && (
                <>
                  <MenuItem
                    onClick={() => handleSelect(APP_ROUTES.AdvertiserCampaign)}
                    selected={selectOp.includes(APP_ROUTES.AdvertiserCampaign)}
                    sx={{ ...style }}
                  >
                    <CampaignOutlinedIcon />
                    <ListItemText sx={{ ml: 1 }}>Campaign List(A)</ListItemText>
                  </MenuItem>
                </>
              )}

              {commonDetail.userType == USER_ROLE.ADMIN && (
                <>
                  <MenuItem
                    onClick={() => handleSelect(APP_ROUTES.CampaignsList)}
                    selected={selectOp == APP_ROUTES.CampaignsList}
                    sx={{ ...style }}
                  >
                    <CampaignOutlinedIcon />
                    <ListItemText sx={{ ml: 1 }}>Campaign List</ListItemText>
                  </MenuItem>

                  <MenuItem
                    onClick={() => handleSelect(APP_ROUTES.CreateAdvertisers)}
                    selected={selectOp == APP_ROUTES.CreateAdvertisers}
                    sx={{ ...style }}
                  >
                    <AssignmentOutlinedIcon />
                    <ListItemText sx={{ ml: 1 }}>Advertiser</ListItemText>
                  </MenuItem>

                  {/* <MenuItem
                    onClick={() => handleSelect(APP_ROUTES.CreateCampaign)}
                    selected={selectOp.includes(APP_ROUTES.CreateCampaign)}
                    sx={{ ...style }}
                  >
                    <AddchartOutlinedIcon />
                    <ListItemText sx={{ ml: 1 }}>Create Campaign</ListItemText>
                  </MenuItem> */}

                  <MenuItem
                    onClick={() => handleSelect(APP_ROUTES.DriverList)}
                    selected={selectOp == APP_ROUTES.DriverList}
                    sx={{ ...style }}
                  >
                    <DescriptionOutlinedIcon />
                    <ListItemText sx={{ ml: 1 }}>Driver List</ListItemText>
                  </MenuItem>
                </>
              )}

              {/* <MenuItem onClick={Logout} sx={{ ...style }}>
                <LogoutOutlinedIcon />
                <ListItemText sx={{ ml: 1 }}>Log out</ListItemText>
              </MenuItem> */}

              {/* <MenuItem sx={style} onClick={() => menuClick("project")}>
                <ExploreOutlinedIcon fontSize="small" />
                <ListItemText sx={{ ml: 1 }}>Projects</ListItemText>
                {menuOpen.project ? <ExpandMoreIcon /> : <NavigateNextIcon />}
              </MenuItem>
              <Collapse timeout="auto" in={menuOpen.project} unmountOnExit>
                <MenuItem
                  onClick={() => handleSelect("/project")}
                  selected={selectOp == "/project"}
                  sx={style}
                >
                  <ArrowRightIcon fontSize="small" />
                  <ListItemText sx={{ ml: 1 }}>project</ListItemText>
                </MenuItem>
              </Collapse> */}
            </Box>
          </Box>
          {/* <Box>
            <List>
              <Typography color="#A4A4A4">Pages</Typography>
            </List>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 0.6 }}>
              <MenuItem
                onClick={() => menuClick("profile")}
                sx={{
                  "&:hover": {
                    bgcolor: "#F3F3F3",
                  },
                }}
              >
                <ExploreOutlinedIcon fontSize="small" />
                <ListItemText sx={{ ml: 1 }}>User Profile</ListItemText>
                {menuOpen.profile ? <ExpandMoreIcon /> : <NavigateNextIcon />}
              </MenuItem>
              <Collapse timeout="auto" in={menuOpen.profile} unmountOnExit>
                <MenuItem
                  onClick={() => handleSelect("/profile")}
                  selected={selectOp == "/profile"}
                  sx={style}
                >
                  <ArrowRightIcon fontSize="small" />
                  <ListItemText sx={{ ml: 1 }}>Profile</ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={() => handleSelect("/Overview")}
                  selected={selectOp == "/Overview"}
                  sx={style}
                >
                  <ArrowRightIcon fontSize="small" />
                  <ListItemText sx={{ ml: 1 }}>Overview</ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={() => handleSelect("/Projects")}
                  selected={selectOp == "/Projects"}
                  sx={style}
                >
                  <ArrowRightIcon fontSize="small" />
                  <ListItemText sx={{ ml: 1 }}>Projects</ListItemText>
                </MenuItem>
              </Collapse>

              <MenuItem sx={style} onClick={() => menuClick("account")}>
                <ExploreOutlinedIcon fontSize="small" />
                <ListItemText sx={{ ml: 1 }}>Account</ListItemText>
                {menuOpen.account ? <ExpandMoreIcon /> : <NavigateNextIcon />}
              </MenuItem>
              <Collapse timeout="auto" in={menuOpen.account} unmountOnExit>
                <MenuItem
                  onClick={() => handleSelect("/account")}
                  selected={selectOp == "/account"}
                  sx={style}
                >
                  <ArrowRightIcon fontSize="small" />
                  <ListItemText sx={{ ml: 1 }}>Account</ListItemText>
                </MenuItem>
              </Collapse>

              <MenuItem sx={style} onClick={() => menuClick("corp")}>
                <ExploreOutlinedIcon fontSize="small" />
                <ListItemText sx={{ ml: 1 }}>Corporate</ListItemText>
                {menuOpen.corp ? <ExpandMoreIcon /> : <NavigateNextIcon />}
              </MenuItem>
              <Collapse timeout="auto" in={menuOpen.corp} unmountOnExit>
                <MenuItem
                  onClick={() => handleSelect("/corp")}
                  selected={selectOp == "/corp"}
                  sx={style}
                >
                  <ArrowRightIcon fontSize="small" />
                  <ListItemText sx={{ ml: 1 }}>Corporate</ListItemText>
                </MenuItem>
              </Collapse>

              <MenuItem sx={style} onClick={() => menuClick("blog")}>
                <ExploreOutlinedIcon fontSize="small" />
                <ListItemText sx={{ ml: 1 }}>Blog</ListItemText>
                {menuOpen.blog ? <ExpandMoreIcon /> : <NavigateNextIcon />}
              </MenuItem>
              <Collapse timeout="auto" in={menuOpen.blog} unmountOnExit>
                <MenuItem
                  onClick={() => handleSelect("/blog")}
                  selected={selectOp == "/blog"}
                  sx={style}
                >
                  <ArrowRightIcon fontSize="small" />
                  <ListItemText sx={{ ml: 1 }}>Blog</ListItemText>
                </MenuItem>
              </Collapse>
            </Box>
          </Box> */}
        </Box>
      </Box>
    </Box>
  );
};

export default Sidebar;
