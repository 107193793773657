import React, { useEffect, useState } from "react";
import icon from "../../assets/images/icon.png";
import { APP_ROUTES } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Typography,
  styled,
} from "@mui/material";
const Header = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [language, setLanguage] = useState(i18n.language);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const availableLanguage = ["en", "fi"];

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    setLanguage(lng);
  };
  const setLng = () => {
    if (language && availableLanguage.includes(language)) {
      if (language === "en") {
        setSelectedLanguage("en");
      } else {
        setSelectedLanguage("fi");
      }
    } else {
      setSelectedLanguage("en");
      i18n.changeLanguage("en");
    }
  };
  useEffect(() => {
    setLng();
  }, [setLanguage, language]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          p: 2,
          px: { xs: 2, sm: 4 },
          alignItems: "center",
          gap: 1,
        }}
      >
        <Box>
          <Box
            sx={{ cursor: "pointer" }}
            onClick={() => navigate(APP_ROUTES.Landing)}
            component="img"
            src={icon}
            width={"110px"}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: { xs: 2, sm: 4 },
          }}
        >
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedLanguage}
            onChange={(e) => changeLanguage(e.target.value)}
            size="small"
          >
            <MenuItem value={"en"}>English</MenuItem>
            <MenuItem value={"fi"}>Finnish</MenuItem>
          </Select>

          <Typography
            sx={{ cursor: "pointer", display: { xs: "none", sm: "unset" } }}
          >
            {t("header.drivers")}
          </Typography>
          <Typography
            sx={{ cursor: "pointer", display: { xs: "none", sm: "unset" } }}
          >
            {t("header.brands&agencies")}
          </Typography>
          <Button
            variant="contained"
            onClick={() => navigate(APP_ROUTES.Register)}
            sx={{
              fontWeight: "700",
            }}
          >
            {t("header.register")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
