import { createTheme } from "@mui/material";
import { red } from "@mui/material/colors";

// const colors = {
//   primary: '#06C7BB',
//   secondary: '#06C7BB',
//   light: '#484848',
//   lighter: '#F6F6F6',
//   white: '#464646',
//   black: '#000000'
// };
// Create a theme instance.
let theme = createTheme({
  // breakpoints: {
  //   values: {
  //     xs: 0,
  //     sm: 567,
  //     md: 768,
  //     lg: 992,
  //     xl: 1440
  //   }
  // },
  palette: {
    primary: {
      light: "#4A3AFF",
      main: "#0096FF",
      dark: "#0096FF",
      contrastText: "#fff",
    },
    secondary: {
      light: "#067ae6",
      main: "#FFFFFF",
      dark: "#FFFFFF",
      contrastText: "#000",
    },
    text: {
      primary: "#464646",
    },
    error: {
      main: red.A700,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "capitalize", // Set the desired text transformation here
          // Other button styles can be added here
        },
      },
    },
  },
});

export default theme;
