import {
  Box,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Grid,
  Button,
  Stack,
} from "@mui/material";
import React, { useState } from "react";
import HttpService from "../../../hooks/Https-services";
import { API_ROUTES, APP_ROUTES, status_code } from "../../../utils/constants";
import { toast } from "react-toastify";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import NoCrashOutlinedIcon from "@mui/icons-material/NoCrashOutlined";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";

const Campaigns = (props: any) => {
  const { url, name, location, cpm, added, car_type, id, code, getCampaign } =
    props;
  const [load, setLoad] = useState(false);
  const navigate = useNavigate();
  const handleDelete = async (id: any) => {
    try {
      setLoad(true);
      const res = await HttpService.del(
        API_ROUTES.WithdrawalCampaign.replace(":id", id)
      );
      await getCampaign(status_code.request, 1, 1);
      toast.success("Withdrawal Successfully");
      setLoad(false);
    } catch (error) {
      setLoad(false);
      console.log(error);
    }
  };
  return (
    <Box>
      <Card
        sx={{
          borderRadius: "10px",
          border: "1px solid #0096FF6e ",
        }}
      >
        <CardMedia
          sx={{ height: 240, cursor: "pointer" }}
          image={url}
          onClick={() =>
            navigate(APP_ROUTES.CampaignDetails.replace(":id", id))
          }
        />
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12} ml={0.5}>
              <Typography variant="h6" sx={{ fontWeight: "600" }}>
                {name}
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ flexWrap: "x`" }}>
              <Stack spacing={1} direction="row" alignItems="center">
                <AttachMoneyOutlinedIcon />
                <Typography variant="body2" sx={{ fontWeight: "600" }}>
                  Cpm :
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="body2"
                color={"gray"}
                sx={{ fontWeight: "500" }}
              >
                {cpm}$
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Stack spacing={1} direction="row" alignItems="center">
                <AccountCircleOutlinedIcon sx={{ fontSize: "22px" }} />
                <Typography variant="body2" sx={{ fontWeight: "600" }}>
                  Applicants :
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="body2"
                component="span"
                color={"gray"}
                sx={{ fontWeight: "500" }}
              >
                {added}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Stack spacing={1} direction="row" alignItems="center">
                <LocationOnOutlinedIcon sx={{ fontSize: "22px" }} />
                <Typography variant="body2" sx={{ fontWeight: "600" }}>
                  Location :
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="body2"
                component="span"
                color={"gray"}
                sx={{ fontWeight: "500" }}
              >
                {location}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Stack spacing={1} direction="row" alignItems="center">
                <NoCrashOutlinedIcon sx={{ fontSize: "22px" }} />
                <Typography variant="body2" sx={{ fontWeight: "600" }}>
                  Car Type :
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="body2"
                component="span"
                color={"gray"}
                sx={{ fontWeight: "500" }}
              >
                {car_type}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        {code == 0 && (
          <Box sx={{ px: 2, pb: 2 }}>
            <LoadingButton
              loading={load}
              type="submit"
              fullWidth
              variant="contained"
              onClick={() => handleDelete(id)}
            >
              Withdrawal
            </LoadingButton>
          </Box>
        )}
      </Card>
    </Box>
  );
};

export default Campaigns;
