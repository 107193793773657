import { Box, Button, Grid, Skeleton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import useCommonDetails from "../../hooks/useCommonDetails";
import HttpService from "../../hooks/Https-services";
import { API_ROUTES, APP_ROUTES } from "../../utils/constants";
import Campaigns from "./Campaigns";
import { useNavigate } from "react-router-dom";
import Style from "../../style/Common.style";

const AdvertiserCampaign = () => {
  const [load, setLoad] = useState(false);
  const userDetail = useCommonDetails();
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const emptyArray = new Array(6).fill(0);
  const getCampaign = async () => {
    try {
      setLoad(true);
      const data: any = {
        query: {
          userId: userDetail.id,
          isDeleted: false,
        },
        options: {
          // page: 1,
          // paginate: 10,
        },
        isCountOnly: false,
      };

      const res = await HttpService.post(API_ROUTES.CampaignList, data);
      const temp: any = [];
      res.data.data?.map((ele: any) => {
        const name = ele.name;
        const url = ele.adImages[0].image;
        const car_type = ele.car_type.model?.map((ele: any) => ele).join(",");
        const added = ele.numberOfUserApplied;
        const location = ele.location.city;
        const cpm = ele.cpm;
        const id = ele.id;
        temp.push({
          name: name,
          url: url,
          car_type: car_type,
          added: added,
          location: location,
          cpm: cpm,
          id: id,
        });
      });
      setData(temp);
      setLoad(false);
    } catch (error) {
      setLoad(false);
      console.log(error);
    }
  };
  useEffect(() => {
    getCampaign();
  }, []);
  return (
    <Box p={2}>
      <Box sx={Style.StickyHeaderStyle}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" mb={1} fontWeight={500}>
            Campaign List
          </Typography>
          <Button
            variant="contained"
            onClick={() => navigate(APP_ROUTES.CreateCampaign)}
          >
            Add Campaign
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          // display: "flex",
          // flexWrap: "wrap",
          // justifyContent: "center",
          // gap: 5,
          mt: 3,
          px: 3,
          width: "100%",
        }}
      >
        <Grid container spacing={3} pr={1}>
          {!load ? (
            data.length > 0 ? (
              data.map((ele: any) => (
                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <Campaigns
                    url={ele.url}
                    name={ele.name}
                    location={ele.location}
                    cpm={ele.cpm}
                    added={ele.added}
                    car_type={ele.car_type}
                    id={ele.id}
                    getCampaign={getCampaign}
                  />
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                <Typography variant="body1">
                  No data found on this filte!
                </Typography>
              </Grid>
            )
          ) : (
            <Box sx={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
              <Grid container spacing={3} pr={1}>
                {emptyArray.map((ele: any) => (
                  <Grid item xs={12} sm={6} md={6} lg={4} sx={{ flexGrow: 1 }}>
                    <Skeleton
                      variant="rectangular"
                      height={400}
                      sx={{ maxWidth: "500px" }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default AdvertiserCampaign;
