import { Box, Typography } from "@mui/material";
import React from "react";
import logo from "../../assets/images/icon_white.png";
import social1 from "../../assets/images/social1.png";
import social2 from "../../assets/images/social2.png";
import social3 from "../../assets/images/social3.png";
import social4 from "../../assets/images/social4.png";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { API_ROUTES, APP_ROUTES } from "../../utils/constants";
const Footer = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: "center",
          p: 3,
          px: 4,
          bgcolor: "#0096FF",
        }}
      >
        <Box sx={{ flexGrow: 0.7 }} px={2}>
          <Box
            sx={{ cursor: "pointer" }}
            onClick={() => navigate(APP_ROUTES.Landing)}
            component="img"
            src={logo}
            width={110}
          />
          <Box sx={{ display: "flex", gap: 1, mt: 1 }}>
            <Box component="img" src={social1} width={36} />
            <Box component="img" src={social2} width={36} />
            <Box component="img" src={social3} width={36} />
            <Box component="img" src={social4} width={36} />
          </Box>
          {/* <Typography color="white" variant="body2" mt={1}>
            Lorem Ipsum is simply dummy text of the printing and typesetting{" "}
          </Typography> */}

          {/* <Typography color="white" variant="body2" mt={1}>
            Copyright © GoAds®. All rights reserved.
          </Typography> */}
        </Box>

        <Box sx={{ flexGrow: 0.3, px: 2 }}>
          {/* <Typography color="white" variant="h5" mt={1}>
            Quick Links
          </Typography> */}
          <Box
            sx={{
              display: "flex",
              gap: 5,
              width: "80%",
              mt: 2,
            }}
          >
            <Box>
              <Typography variant="body1" color="white" fontWeight="500">
                {t("footer.support")}
              </Typography>
              <Typography
                variant="body1"
                color="white"
                mt={0.7}
                fontWeight="500"
              >
                {t("footer.contactus")}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1" color="white" fontWeight="500">
                {t("footer.rules")}
              </Typography>
              <Typography
                sx={{ cursor: "pointer" }}
                variant="body1"
                color="white"
                mt={0.7}
                fontWeight="500"
                onClick={() => navigate(APP_ROUTES.PrivacyPolicy)}
              >
                {t("footer.privacy")}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1" color="white" fontWeight="500">
                {t("footer.terms")}
              </Typography>
            </Box>
          </Box>
          <Typography color="#cccccc" variant="body2" mt={1.5}>
            {t("footer.copyright")}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
