import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import bg from "../../assets/images/bg.png";
import logo from "../../assets/images/icon.png";
import { useNavigate } from "react-router-dom";
import { API_ROUTES, APP_ROUTES } from "../../utils/constants";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import HttpService from "../../hooks/Https-services";
import { LoadingButton } from "@mui/lab";
import ExampleHeatmap from "../Map";
import { usePlacesWidget } from "react-google-autocomplete";
import MyMap from "../Map";
import GetPlaceIdForCity from "../Map/place";
import { useTranslation } from "react-i18next";

const RegisterPage = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data: any) => {
    try {
      if (data.password != data.confirmPassword) {
        toast.error("Password does not match!");
        return;
      }
      setLoad(true);
      const dataBody = {
        username: data.username,
        password: data.password,
        email: data.email,
        name: data.name,
        location: watch("fullLocation"),
        mobileNo: data.number,
      };
      const res = await HttpService.post(API_ROUTES.Register, dataBody);
      toast.success("Register Successfully");
      reset();
      navigate(APP_ROUTES.Login);
      setLoad(false);
    } catch (error) {
      console.log(error);
      setLoad(false);
    }
  };
  const toAddress = (place: any) => {
    const { lat, lng } = place.geometry.location;
    const location = { lat: lat(), lon: lng() };
    return extractAddress(place.address_components, location);
  };
  const extractAddress = (addresses: any[], location: any) => {
    const address = {
      location,
      country: extractAddressData("country", addresses),
      street1: extractAddressStreetData(addresses),
      street2: extractAddressData("sublocality_level_2", addresses),
      city: extractAddressData("locality", addresses),
      state: extractAddressData("administrative_area_level_1", addresses),
      zip: extractAddressData("postal_code", addresses),
    };

    return address;
  };
  const extractAddressData = (name: string, addresses: any[]) =>
    addresses.find((c) => c.types.includes(name))?.long_name || "";

  const extractAddressStreetData = (addresses: any[]) => {
    const street = extractAddressData("sublocality_level_1", addresses);

    if (street) {
      const route = extractAddressData("route", addresses);

      if (route) {
        return `${street} ${route}`;
      }
    }

    return "";
  };
  const checkKeyDown = (e: any) => {
    if (e.key === "Enter") e.preventDefault();
  };
  const AutocompleteAdd = () => {
    const refs = usePlacesWidget<HTMLDivElement>({
      libraries: ["places"],
      options: { types: ["address"] },

      onPlaceSelected: (place: any) => {
        //set state
        console.log(place);
        let data = toAddress(place);
        setValue("fullLocation", data);
      },
    });
    return (
      <TextField
        fullWidth
        inputRef={refs.ref}
        label={t("register.location")}
        variant="outlined"
        autoComplete={"off"}
        size="medium"
        InputLabelProps={{ shrink: true }}
        InputProps={{
          style: { color: "black" },
        }}
        {...register("location", {
          required: "This field is required.",
        })}
        sx={{
          mt: 2,
          mb: 1,
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "black",
            },
          },
          "& .MuiFormLabel-root": {
            color: "black !important",
          },
        }}
        error={!!errors.location}
        helperText={
          errors.location && (
            <Typography variant="caption" sx={{ color: "red" }}>
              {errors.location.message as any}
            </Typography>
          )
        }
      />
    );
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <Box
        sx={{
          flexGrow: 1,
          backgroundImage: `url(${bg})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover",
          objectFit: "contain",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Card
            sx={{
              px: { xs: 3, sm: 6 },
              py: { xs: 2, sm: 3 },
              width: { xs: "70%", sm: "416px" },
              borderRadius: "20px",
            }}
          >
            <form
              onSubmit={handleSubmit(onSubmit)}
              onKeyDown={(e) => checkKeyDown(e)}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: { xs: 0, sm: 1 },
                }}
              >
                <Box component="img" src={logo} width={{ xs: 110, sm: 140 }} />
              </Box>
              <CardContent sx={{ p: "8px !important" }}>
                <TextField
                  fullWidth
                  label={t("register.name")}
                  variant="outlined"
                  size="medium"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    style: { color: "black" },
                  }}
                  {...register("name", {
                    required: "This field is required.",
                  })}
                  sx={{
                    mt: 2,
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "black",
                      },
                    },
                    "& .MuiFormLabel-root": {
                      color: "black !important",
                    },
                  }}
                  error={!!errors.name}
                  helperText={
                    errors.name && (
                      <Typography variant="caption" sx={{ color: "red" }}>
                        {errors.name.message as any}
                      </Typography>
                    )
                  }
                />
                <TextField
                  fullWidth
                  label={t("register.username")}
                  variant="outlined"
                  size="medium"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    style: { color: "black" },
                  }}
                  {...register("username", {
                    required: "This field is required.",
                  })}
                  sx={{
                    mt: 2,
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "black",
                      },
                    },
                    "& .MuiFormLabel-root": {
                      color: "black !important",
                    },
                  }}
                  error={!!errors.username}
                  helperText={
                    errors.username && (
                      <Typography variant="caption" sx={{ color: "red" }}>
                        {errors.username.message as any}
                      </Typography>
                    )
                  }
                />
                <TextField
                  fullWidth
                  label={t("register.email")}
                  variant="outlined"
                  size="medium"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    style: { color: "black" },
                  }}
                  {...register("email", {
                    required: "This field is required.",
                    pattern: {
                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: "Please enter valid email.",
                    },
                  })}
                  sx={{
                    mt: 2,
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "black",
                      },
                    },
                    "& .MuiFormLabel-root": {
                      color: "black !important",
                    },
                  }}
                  error={!!errors.email}
                  helperText={
                    errors.email && (
                      <Typography variant="caption" sx={{ color: "red" }}>
                        {errors.email.message as any}
                      </Typography>
                    )
                  }
                />
                <TextField
                  fullWidth
                  label={t("register.phonenumber")}
                  variant="outlined"
                  size="medium"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    style: { color: "black" },
                  }}
                  {...register("number", {
                    required: "This field is required.",
                    pattern: {
                      value: /^\d{10}$/,
                      message: "Please enter a valid number",
                    },
                  })}
                  sx={{
                    mt: 2,
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "black",
                      },
                    },
                    "& .MuiFormLabel-root": {
                      color: "black !important",
                    },
                  }}
                  error={!!errors.number}
                  helperText={
                    errors.number && (
                      <Typography variant="caption" sx={{ color: "red" }}>
                        {errors.number.message as any}
                      </Typography>
                    )
                  }
                />
                <TextField
                  fullWidth
                  label={t("register.password")}
                  type="password"
                  size="medium"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    style: { color: "black" },
                  }}
                  {...register("password", {
                    required: "This field is required.",
                    minLength: {
                      value: 8,
                      message: "Password must be at least 8 characters long.",
                    },
                  })}
                  sx={{
                    mt: 2,
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "black",
                      },
                    },
                    "& .MuiFormLabel-root": {
                      color: "black !important",
                    },
                  }}
                  error={!!errors.password}
                  helperText={
                    errors.password && (
                      <Typography variant="caption" sx={{ color: "red" }}>
                        {errors.password.message as any}
                      </Typography>
                    )
                  }
                />
                <TextField
                  fullWidth
                  label={t("register.confirmpassword")}
                  type="password"
                  variant="outlined"
                  size="medium"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    style: { color: "black" },
                  }}
                  {...register("confirmPassword", {
                    required: "This field is required.",
                  })}
                  sx={{
                    mt: 2,
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "black",
                      },
                    },
                    "& .MuiFormLabel-root": {
                      color: "black !important",
                    },
                  }}
                  error={!!errors.confirmPassword}
                  helperText={
                    errors.confirmPassword && (
                      <Typography variant="caption" sx={{ color: "red" }}>
                        {errors.confirmPassword.message as any}
                      </Typography>
                    )
                  }
                />
                <AutocompleteAdd />
              </CardContent>
              <CardActions sx={{ flexDirection: "column" }}>
                <LoadingButton
                  loading={load}
                  fullWidth
                  type="submit"
                  variant="contained"
                  sx={{ py: { xs: "6px", sm: 1 } }}
                >
                  {t("register.signup")}
                </LoadingButton>
                <Typography variant="body2" mt={2}>
                  {t("register.haveanaccount")}{" "}
                  <Typography
                    onClick={() => navigate(APP_ROUTES.Login)}
                    variant="body2"
                    component="span"
                    color="#1676F3"
                    sx={{ textDecoration: "underline", cursor: "pointer" }}
                  >
                    {t("register.signin")}
                  </Typography>
                </Typography>
              </CardActions>
            </form>
          </Card>
        </Box>

        {/* <GetPlaceIdForCity /> */}
        {/* <ExampleHeatmap /> */}
      </Box>
    </Box>
  );
};

export default RegisterPage;
