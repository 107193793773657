import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import bg from "../../assets/images/bg.png";
import logo from "../../assets/images/icon.png";
import { API_ROUTES, APP_ROUTES } from "../../utils/constants";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import HttpService from "../../hooks/Https-services";
import { useDispatch } from "react-redux";
import commonDetailSlice from "../../store/reducers/commonReducer";
import useCommonDetails from "../../hooks/useCommonDetails";
import { toast } from "react-toastify";
import { setAuth } from "../../hooks/useRefreshToken";
import { LoadingButton } from "@mui/lab";

const LoginPage = () => {
  const { t, i18n } = useTranslation();
  const [isReset, setIsReset] = useState(false);
  const [pass, setPass] = useState(false);
  const [scode, setCode] = useState("");
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const code = searchParams.get("reset_code");
  const [load, setLoad] = useState(false);

  async function getEntries(data: any) {
    delete data.tokens;
    dispatch(commonDetailSlice.actions.removecommonDetails());
    dispatch(commonDetailSlice.actions.setcommonDetails(data));
  }
  useEffect(() => {
    if (code) {
      setCode(code);
      searchParams.delete("reset_code");
      setSearchParams(searchParams);
      setIsReset(true);
      setPass(true);
    }
  }, [code]);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    reset,
    clearErrors,
    unregister,
    formState: { errors },
  } = useForm();
  console.log(code);
  const onSubmit = async (data: any) => {
    try {
      setLoad(true);
      const dataBody = {
        username: data.email,
        password: data.password,
      };
      const res = await HttpService.post(API_ROUTES.Login, dataBody);
      getEntries(res.data.data);
      setAuth(res.data.data);
      navigate(APP_ROUTES.Dashboard);
      toast.success("Login Successfully");
      setLoad(false);
    } catch (error) {
      console.log(error);
      setLoad(false);
    }
  };
  const onForgot = async (data: any) => {
    try {
      setLoad(true);
      const res = await HttpService.post(API_ROUTES.ForgotPassword, data);
      toast.success("Reset link send successfully.");
      setLoad(false);
    } catch (error) {
      console.log(error);
      setLoad(false);
    }
  };
  const onReset = async (data: any) => {
    try {
      setLoad(true);
      const dataBody = {
        code: scode,
        newPassword: data.newPassword,
      };
      const res = await HttpService.put(API_ROUTES.ResetPassword, dataBody);
      toast.success("Reset password successfully.");
      setIsReset(false);
      reset();
      setPass(false);
      setLoad(false);
    } catch (error) {
      console.log(error);
      setLoad(false);
    }
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <Box
        sx={{
          flexGrow: 1,
          backgroundImage: `url(${bg})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover",
          objectFit: "contain",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Card
            sx={{
              px: { xs: 3, sm: 6 },
              py: { xs: 4, sm: 6 },
              width: { xs: "70%", sm: "416px" },
              borderRadius: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: { xs: 0, sm: 1 },
              }}
            >
              <Box component="img" src={logo} width={{ xs: 110, sm: 140 }} />
            </Box>
            {!isReset ? (
              <form onSubmit={handleSubmit(onSubmit)}>
                <CardContent sx={{ p: "8px !important" }}>
                  <TextField
                    fullWidth
                    label={t("login.email/username")}
                    variant="outlined"
                    size="medium"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      style: { color: "black" },
                    }}
                    {...register("email", {
                      required: "This field is required.",
                    })}
                    sx={{
                      mt: 3,
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "black",
                        },
                      },
                      "& .MuiFormLabel-root": {
                        color: "black !important",
                      },
                    }}
                    error={!!errors.email}
                    helperText={
                      errors.email && (
                        <Typography variant="caption" sx={{ color: "red" }}>
                          {errors.email.message as any}
                        </Typography>
                      )
                    }
                  />
                  <TextField
                    fullWidth
                    label={t("login.password")}
                    type="password"
                    variant="outlined"
                    size="medium"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      style: { color: "black" },
                    }}
                    {...register("password", {
                      required: "This field is required.",
                      minLength: {
                        value: 8,
                        message: "Password must be at least 8 characters long.",
                      },
                    })}
                    sx={{
                      mt: 3,
                      mb: 0.5,
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "black",
                        },
                      },
                      "& .MuiFormLabel-root": {
                        color: "black !important",
                      },
                    }}
                    error={!!errors.password}
                    helperText={
                      errors.password && (
                        <Typography variant="caption" sx={{ color: "red" }}>
                          {errors?.password?.message as any}
                        </Typography>
                      )
                    }
                  />
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Typography
                      variant="body2"
                      mb={0.5}
                      fontSize={{ xs: "0.90rem", md: "1rem" }}
                      onClick={() => setIsReset(true)}
                      sx={{
                        textAlign: "right",
                        cursor: "pointer",
                        "&:hover": { textDecoration: "underline" },
                      }}
                    >
                      {t("login.forgotpassword")}
                    </Typography>
                  </Box>
                </CardContent>
                <CardActions sx={{ flexDirection: "column" }}>
                  <LoadingButton
                    loading={load}
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ py: { xs: "6px", sm: 1 } }}
                  >
                    {t("login.signin")}
                  </LoadingButton>
                  <Typography variant="body2" mt={5}>
                    {t("login.don’thaveanaccount")}{" "}
                    <Typography
                      onClick={() => navigate(APP_ROUTES.Register)}
                      variant="body2"
                      component="span"
                      color="#1676F3"
                      sx={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                      {t("login.signup")}
                    </Typography>
                  </Typography>
                </CardActions>
              </form>
            ) : !pass ? (
              <form onSubmit={handleSubmit(onForgot)}>
                <CardContent sx={{ p: "8px !important" }}>
                  <Typography variant="h5" mt={2} ml={0.5} fontWeight={600}>
                    {t("login.resetpassword")}
                  </Typography>
                  <TextField
                    fullWidth
                    label={t("login.email")}
                    autoComplete="off"
                    variant="outlined"
                    size="medium"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      style: { color: "black" },
                    }}
                    {...register("email", {
                      required: "This field is required.",
                    })}
                    sx={{
                      mt: 3,
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "black",
                        },
                      },
                      "& .MuiFormLabel-root": {
                        color: "black !important",
                      },
                    }}
                    error={!!errors.email}
                    helperText={
                      errors.email && (
                        <Typography variant="caption" sx={{ color: "red" }}>
                          {errors.email.message as any}
                        </Typography>
                      )
                    }
                  />
                </CardContent>
                <CardActions sx={{ flexDirection: "column" }}>
                  <LoadingButton
                    loading={load}
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ py: { xs: "6px", sm: 1 } }}
                  >
                    {t("login.reset")}
                  </LoadingButton>
                </CardActions>
              </form>
            ) : (
              <form onSubmit={handleSubmit(onReset)}>
                <CardContent sx={{ p: "8px !important" }}>
                  <Typography variant="h5" mt={2} ml={0.5} fontWeight={600}>
                    {t("login.resetpassword")}
                  </Typography>
                  <TextField
                    fullWidth
                    label={t("login.password")}
                    type="password"
                    variant="outlined"
                    size="medium"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      style: { color: "black" },
                    }}
                    {...register("newPassword", {
                      required: "This field is required.",
                      minLength: {
                        value: 8,
                        message: "Password must be at least 8 characters long.",
                      },
                    })}
                    sx={{
                      mt: 3,
                      mb: 0.5,
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "black",
                        },
                      },
                      "& .MuiFormLabel-root": {
                        color: "black !important",
                      },
                    }}
                    error={!!errors.newPassword}
                    helperText={
                      errors.newPassword && (
                        <Typography variant="caption" sx={{ color: "red" }}>
                          {errors?.newPassword?.message as any}
                        </Typography>
                      )
                    }
                  />
                </CardContent>
                <CardActions sx={{ flexDirection: "column" }}>
                  <LoadingButton
                    loading={load}
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ py: { xs: "6px", sm: 1 } }}
                  >
                    {t("login.reset")}
                  </LoadingButton>
                </CardActions>
              </form>
            )}
          </Card>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginPage;
