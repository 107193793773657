import {
  Avatar,
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  ListItemIcon,
  Divider,
  Tooltip,
  Select,
} from "@mui/material";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import React, { useEffect, useState } from "react";
import icon from "../../assets/images/icon.png";
import { APP_ROUTES, USER_ROLE } from "../../utils/constants";
import { useLocation, useNavigate } from "react-router-dom";
import useCommonDetails from "../../hooks/useCommonDetails";
import MenuIcon from "@mui/icons-material/Menu";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import AppRegistrationOutlinedIcon from "@mui/icons-material/AppRegistrationOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import AddchartOutlinedIcon from "@mui/icons-material/AddchartOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const PrivateHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const logo = require("../../assets/images/icon.png");
  const commonDetail = useCommonDetails();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [menuOpen, setMenuOpen] = useState<any>({
    profile: false,
    ecomm: false,
    project: false,
    account: false,
    blog: false,
    corp: false,
  });
  const [selectOp, setSelectOp] = useState(location.pathname);

  useEffect(() => {
    setSelectOp(location.pathname);
  }, [location.pathname]);

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };
  const handleSelect = (e: any) => {
    setSelectOp(e);
    navigate(e);
  };
  const menuClick = (name: any) => {
    const item = menuOpen;
    item[name] = !item[name];

    setMenuOpen({ ...menuOpen, ...item });
  };
  const Logout = async () => {
    try {
      localStorage.removeItem("auth");
      navigate(APP_ROUTES.Landing);
      toast.success("Logout sucessfully");
    } catch (err) {
      console.log(err);
    }
  };
  const style = {
    "&.Mui-selected": {
      bgcolor: "#0096FF",
      color: "white",
      "&:hover": {
        bgcolor: "#0096FF",
      },
    },
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const availableLanguage = ["en", "fi"];

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    setLanguage(lng);
  };
  const setLng = () => {
    if (language && availableLanguage.includes(language)) {
      if (language === "en") {
        setSelectedLanguage("en");
      } else {
        setSelectedLanguage("fi");
      }
    } else {
      setSelectedLanguage("en");
      i18n.changeLanguage("en");
    }
  };
  useEffect(() => {
    setLng();
  }, [setLanguage, language]);
  return (
    <Box
      sx={{
        position: "sticky",
        top: 0,
        background: "#fafbfc",
        zIndex: 1000,
        mb: -1,
        borderBottom: "1px solid lightgray",
      }}
    >
      <Box
        sx={{
          display: "flex",
          // visibility: { xs: "visible", md: "hidden" },
          justifyContent: "space-between",
          p: 2,
          px: { xs: 2, sm: 2 },
          alignItems: "center",
          gap: 1,
        }}
      >
        <Box sx={{ visibility: { xs: "visible", md: "hidden" } }}>
          <Box component="img" src={icon} width={"100px"} />
        </Box>

        <IconButton
          onClick={handleOpenDrawer}
          sx={{ visibility: { xs: "visible", md: "hidden" } }}
        >
          <MenuIcon />
        </IconButton>

        <Box sx={{ display: { xs: "none", md: "flex" } }}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedLanguage}
            onChange={(e) => changeLanguage(e.target.value)}
            size="small"
          >
            <MenuItem value={"en"}>English</MenuItem>
            <MenuItem value={"fi"}>Finnish</MenuItem>
          </Select>

          <Box
            sx={{
              alignItems: "center",
              textAlign: "center",
              display: { xs: "none", md: "flex" },
            }}
          >
            <Tooltip title="Account settings">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <Avatar sx={{ width: 32, height: 32 }}>P</Avatar>
              </IconButton>
            </Tooltip>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&::before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem
              onClick={() => handleSelect(APP_ROUTES.Profile)}
              selected={selectOp == APP_ROUTES.Profile}
              sx={{ ...style }}
            >
              <PermIdentityIcon />
              <ListItemText sx={{ ml: 1 }}>Profile</ListItemText>
            </MenuItem>

            <MenuItem onClick={Logout} sx={{ ...style, borderRadius: "8px" }}>
              <LogoutOutlinedIcon />
              <ListItemText sx={{ ml: 1 }}>Log out</ListItemText>
            </MenuItem>
          </Menu>
        </Box>

        <Drawer
          anchor="right" // Position the drawer on the right side
          open={openDrawer}
          onClose={handleCloseDrawer}
        >
          <Box
            sx={{
              minHeight: "100vh",
              width: "250px",
              display: "flex",
              borderRight: "1px solid lightgray",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                p: "20px 24px",
                flexGrow: 1,
                gap: 4,
              }}
            >
              <Box>
                <Box component="img" src={icon} width={"100px"} />
              </Box>
              <Box>
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: 0.6 }}
                >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedLanguage}
                    onChange={(e) => changeLanguage(e.target.value)}
                    size="small"
                  >
                    <MenuItem value={"en"}>English</MenuItem>
                    <MenuItem value={"fi"}>Finnish</MenuItem>
                  </Select>
                  <MenuItem
                    onClick={() => handleSelect(APP_ROUTES.Dashboard)}
                    selected={selectOp == APP_ROUTES.Dashboard}
                    sx={{ ...style, borderRadius: "8px" }}
                  >
                    <HomeOutlinedIcon />
                    <ListItemText sx={{ ml: 1 }}>Home</ListItemText>
                  </MenuItem>

                  {commonDetail.userType == USER_ROLE.DRIVER && (
                    <>
                      {!commonDetail.isDriverOnBoard && (
                        <MenuItem
                          onClick={() => handleSelect(APP_ROUTES.Onboard)}
                          selected={selectOp == APP_ROUTES.Onboard}
                          sx={{ ...style }}
                        >
                          <AppRegistrationOutlinedIcon />
                          <ListItemText sx={{ ml: 1 }}>OnBoard</ListItemText>
                        </MenuItem>
                      )}
                      <MenuItem
                        onClick={() => handleSelect(APP_ROUTES.DriverProfile)}
                        selected={selectOp == APP_ROUTES.DriverProfile}
                        sx={{ ...style, borderRadius: "8px" }}
                      >
                        <PersonOutlineOutlinedIcon />
                        <ListItemText sx={{ ml: 1 }}>Car Profile</ListItemText>
                      </MenuItem>

                      <MenuItem
                        onClick={() => handleSelect(APP_ROUTES.CampaignsList)}
                        selected={selectOp == APP_ROUTES.CampaignsList}
                        sx={{ ...style }}
                      >
                        <CampaignOutlinedIcon />
                        <ListItemText sx={{ ml: 1 }}>
                          Campaign List
                        </ListItemText>
                      </MenuItem>

                      <MenuItem
                        onClick={() => handleSelect(APP_ROUTES.MyCampaign)}
                        selected={selectOp.includes(APP_ROUTES.MyCampaign)}
                        sx={{ ...style, borderRadius: "8px" }}
                      >
                        <AssignmentTurnedInOutlinedIcon />
                        <ListItemText sx={{ ml: 1 }}>My Campaigns</ListItemText>
                      </MenuItem>

                      <MenuItem
                        onClick={() => handleSelect(APP_ROUTES.DriverContract)}
                        selected={selectOp == APP_ROUTES.DriverContract}
                        sx={{ ...style }}
                      >
                        <AssignmentOutlinedIcon />
                        <ListItemText sx={{ ml: 1 }}>Contract</ListItemText>
                      </MenuItem>
                    </>
                  )}

                  {commonDetail.userType == USER_ROLE.ADMIN && (
                    <MenuItem
                      onClick={() => handleSelect(APP_ROUTES.CampaignsList)}
                      selected={selectOp == APP_ROUTES.CampaignsList}
                      sx={{ ...style }}
                    >
                      <CampaignOutlinedIcon />
                      <ListItemText sx={{ ml: 1 }}>Campaign List</ListItemText>
                    </MenuItem>
                  )}

                  {commonDetail.userType == USER_ROLE.ADVERTISER && (
                    <>
                      <MenuItem
                        onClick={() =>
                          handleSelect(APP_ROUTES.AdvertiserCampaign)
                        }
                        selected={selectOp == APP_ROUTES.AdvertiserCampaign}
                        sx={{ ...style, borderRadius: "8px" }}
                      >
                        <CampaignOutlinedIcon />
                        <ListItemText sx={{ ml: 1 }}>
                          Campaign List(A)
                        </ListItemText>
                      </MenuItem>
                    </>
                  )}

                  {commonDetail.userType == USER_ROLE.ADMIN && (
                    <>
                      <MenuItem
                        onClick={() =>
                          handleSelect(APP_ROUTES.CreateAdvertisers)
                        }
                        selected={selectOp == APP_ROUTES.CreateAdvertisers}
                        sx={{ ...style }}
                      >
                        <AssignmentOutlinedIcon />
                        <ListItemText sx={{ ml: 1 }}>
                          Create Advertiser
                        </ListItemText>
                      </MenuItem>

                      <MenuItem
                        onClick={() => handleSelect(APP_ROUTES.CreateCampaign)}
                        selected={selectOp.includes(APP_ROUTES.CreateCampaign)}
                        sx={{ ...style }}
                      >
                        <AddchartOutlinedIcon />
                        <ListItemText sx={{ ml: 1 }}>
                          Create Campaign
                        </ListItemText>
                      </MenuItem>
                    </>
                  )}

                  <MenuItem
                    onClick={Logout}
                    sx={{ ...style, borderRadius: "8px" }}
                  >
                    <LogoutOutlinedIcon />
                    <ListItemText sx={{ ml: 1 }}>Log out</ListItemText>
                  </MenuItem>

                  {/* <MenuItem sx={style} onClick={() => menuClick("project")}>
                <ExploreOutlinedIcon fontSize="small" />
                <ListItemText sx={{ ml: 1 }}>Projects</ListItemText>
                {menuOpen.project ? <ExpandMoreIcon /> : <NavigateNextIcon />}
              </MenuItem>
              <Collapse timeout="auto" in={menuOpen.project} unmountOnExit>
                <MenuItem
                  onClick={() => handleSelect("/project")}
                  selected={selectOp == "/project"}
                  sx={style}
                >
                  <ArrowRightIcon fontSize="small" />
                  <ListItemText sx={{ ml: 1 }}>project</ListItemText>
                </MenuItem>
              </Collapse> */}
                </Box>
              </Box>
            </Box>
          </Box>
        </Drawer>
      </Box>
    </Box>
  );
};

export default PrivateHeader;
