import React from "react";
import Routes from "./Routes";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "./i18n";
function App() {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        limit={2}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        icon={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
      />
      <Routes />
    </>
  );
}

export default App;
