import {
  Avatar,
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import car1 from "../../assets/images/car1.png";
import car2 from "../../assets/images/car2.png";
import car3 from "../../assets/images/car3.png";
import slider1 from "../../assets/images/icon.png";
import slider2 from "../../assets/images/icon.png";
import slider3 from "../../assets/images/icon.png";
import logo1 from "../../assets/images/icon.png";
import logo2 from "../../assets/images/icon.png";
import logo3 from "../../assets/images/icon.png";
import logo4 from "../../assets/images/icon.png";
import logo5 from "../../assets/images/icon.png";
import logo6 from "../../assets/images/icon.png";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import Slider from "react-slick";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { API_ROUTES, APP_ROUTES } from "../../utils/constants";

const LandingPage = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const slides = [slider1, slider2, slider1, slider3];
  const logoSlides = [
    logo1,
    logo2,
    logo3,
    logo4,
    logo5,
    logo6,
    logo1,
    logo2,
    logo3,
    logo4,
    logo5,
    logo6,
  ];
  const sliderSettings = {
    // removes default buttons
    arrows: false,
    slidesToShow: 3,
    autoplay: true,
    autoplaySpeed: 2500,
    slidesToScroll: 1,
    infinite: true,

    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const sliderSettings2 = {
    // removes default buttons
    arrows: false,
    slidesToShow: 10,
    autoplay: true,
    autoplaySpeed: 2500,
    slidesToScroll: 1,
    infinite: true,

    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 8,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  };
  return (
    <Box>
      <Box>
        {/* <Box sx={{ position: "relative" }}>
          <Box component="img" src={carImg1} width="100%" />
          <Box
            sx={{
              position: "absolute",
              display: "flex",
              flexDirection: "column",
              gap: { xs: 2, sm: 3, md: 4 },
              left: "8%",
              top: "10%",
              //   transform: "translate(-50%, -50%)",
            }}
          >
            <Typography
              variant={"h2"}
              sx={{
                color: "white",
                fontWeight: "600",
                fontSize: { xs: "1.75rem", sm: "2.75rem", md: "3.75rem" },
              }}
            >
              Your goals, Our Ads!
            </Typography>
            <Typography
              variant="h6"
              sx={{
                color: "white",
                fontSize: { xs: "14px", sm: "18px", md: "20px" },
              }}
            >
              “get your ads showing on hunders of cars driving daily”{" "}
            </Typography>
            <Box>
              <Button color="secondary" variant="contained">
                Button
              </Button>
            </Box>
          </Box>
        </Box> */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: { xs: "unset", md: "center" },
            background: "#0096FF",
            height: { xs: "40vh", sm: "55vh", md: `${"calc(100vh - 68px)"}` },
            py: { xs: 4, sm: 8 },
            px: { xs: 4, sm: 8 },
            zIndex: "999",
            gap: 2,
            position: "relative",
            boxSizing: "border-box",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              color: "white",
              fontWeight: "700",
              fontSize: { xs: "1.75rem", sm: "2.75rem", md: "3.75rem" },
            }}
          >
            {t("main.label")}
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontSize: { xs: "14px", sm: "18px", md: "20px" },
              color: "white",
            }}
          >
            {t("main.sub_label")}
          </Typography>
          <Box sx={{ mt: 1, zIndex: 1 }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => navigate(APP_ROUTES.Login)}
              sx={{ textTransform: "none" }}
            >
              {t("main.login")}
            </Button>
          </Box>
          <Box
            sx={{
              position: "absolute",
              right: 0,
              bottom: { xs: "0", sm: "-10px" },
            }}
            component="img"
            src={car1}
            // width={{ xs: "800px", sm: "650px", md: "800px" }}
            width={{ xs: "100%", sm: "90%", md: "100%" }}
          />
        </Box>
        <Box sx={{ mt: 6 }}>
          <Typography variant="h6" textAlign="center">
            {t("brand.label")}
          </Typography>
        </Box>
      </Box>
      <Box my={6}>
        <Slider {...sliderSettings2}>
          {logoSlides.map((ele: any) => (
            <Box>
              <Box px={2}>
                <img src={ele} width="100%" />
              </Box>
            </Box>
          ))}
        </Slider>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Typography
          variant="h5"
          sx={{ color: "#363a3e", fontWeight: 600, textAlign: "center" }}
        >
          {t("goadsInsights.label")}
        </Typography>
        <Typography
          variant="h5"
          sx={{ fontWeight: 600, textAlign: "center", mt: 3 }}
        >
          {t("goadsInsights.label2")}
        </Typography>
        <Box sx={{ mt: 6 }}>
          <Box sx={{ width: "70%", margin: "0 auto" }}>
            <Grid container spacing={5}>
              <Grid item xs={12} sm={6} md={4}>
                <Stack direction="row" alignItems="center" gap={1} mb={2}>
                  <DoneAllIcon sx={{ margin: "auto 0" }} />
                  <Typography variant="h6">
                    {t("goadsInsights.head1")}
                  </Typography>
                </Stack>
                <Typography>{t("goadsInsights.p1")}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Stack direction="row" alignItems="center" gap={1} mb={2}>
                  <DoneAllIcon sx={{ margin: "auto 00" }} />
                  <Typography variant="h6">
                    {t("goadsInsights.head2")}
                  </Typography>
                </Stack>
                <Typography>{t("goadsInsights.p2")}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Stack direction="row" alignItems="center" gap={1} mb={2}>
                  <DoneAllIcon sx={{ margin: "auto 00" }} />
                  <Typography variant="h6">
                    {t("goadsInsights.head3")}
                  </Typography>
                </Stack>
                <Typography>{t("goadsInsights.p3")}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Stack direction="row" alignItems="center" gap={1} mb={2}>
                  <DoneAllIcon sx={{ margin: "auto 00" }} />
                  <Typography variant="h6">
                    {t("goadsInsights.head4")}
                  </Typography>
                </Stack>
                <Typography>{t("goadsInsights.p4")}</Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box mt={11}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "40vh",
              background: "#0096FF",
              alignItems: "center",
              flexDirection: { xs: "column-reverse", sm: "row" },
              //   justifyContent: "spcae-evenly",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                px: 2,
                py: 2,
                flexGrow: 1,
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "600",
                  color: "white",
                  textAlign: "center",
                  fontSize: { xs: "0.8rem", md: "1.25rem" },
                }}
              >
                {t("earnupto.label")}{" "}
                <Typography
                  variant="h4"
                  component="span"
                  sx={{
                    fontWeight: "700",
                    fontSize: { xs: "1.6rem", md: "2.125rem" },
                  }}
                >
                  400€/
                </Typography>
                <Typography variant="h6" component="span">
                  {t("earnupto.label2")}
                </Typography>
                <br />
                {t("earnupto.label3")}
              </Typography>
              <Box sx={{ margin: "0 auto" }}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => navigate(APP_ROUTES.Register)}
                >
                  {t("earnupto.btn")}
                </Button>
              </Box>
            </Box>

            <Box
              component="img"
              src={car2}
              maxHeight={"550px"}
              height={{ xs: "80%", sm: "100%", md: "130%" }}
            />
          </Box>
        </Box>
        <Box mt={10}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "40vh",
              background: "#0096FF",
              alignItems: "center",
              flexDirection: { xs: "column-reverse", sm: "row-reverse" },
              //   justifyContent: "spcae-evenly",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                px: 2,
                py: 2,
                flexGrow: 1,
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "600",
                  color: "white",
                  textAlign: "center",
                  fontSize: { xs: "1.5rem", md: "2.00rem" },
                  textShadow:
                    "0px 4.232804298400879px 4.232804298400879px rgba(0, 0, 0, 0.25)",
                }}
              >
                {t("companies.label")}
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "600",
                  color: "white",
                  textAlign: "center",
                  fontSize: { xs: "0.8rem", md: "1.25rem" },
                }}
              >
                {t("companies.label2")}{" "}
                <Typography
                  variant="h4"
                  component="span"
                  sx={{
                    fontWeight: "700",
                    fontSize: { xs: "1.6rem", md: "2.125rem" },
                  }}
                >
                  40%
                </Typography>
                <Typography variant="h6" component="span">
                  {" "}
                  {t("companies.label3")}
                  <br />
                  {t("companies.label4")}
                </Typography>
              </Typography>
              <Box sx={{ margin: "0 auto" }}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => navigate(APP_ROUTES.Register)}
                >
                  {t("companies.btn")}
                </Button>
              </Box>
            </Box>

            <Box
              component="img"
              src={car3}
              maxHeight={"550px"}
              height={{ md: "100%" }}
              width={{ xs: "60%", sm: "50%", md: "unset" }}
            />
          </Box>
        </Box>
        <Box my={10} px={2}>
          <Slider {...sliderSettings}>
            {slides.map((ele: any) => (
              <Box>
                <Box px={2}>
                  <img src={ele} width="100%" />
                </Box>
              </Box>
            ))}
          </Slider>
        </Box>
      </Box>
    </Box>
  );
};

export default LandingPage;
