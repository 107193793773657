import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { APP_ROUTES } from "../utils/constants";

import useCommonDetails from "../hooks/useCommonDetails";
import MainContainer from "../components/MainContainer";
import Header from "../components/Header";
import { getCurrentAuth } from "../hooks/http-server";

const PrivateRoute = (
  { allowRoles, children }: { allowRoles: any; children: React.ReactElement },
  props: any
) => {
  const isAuthenticated = getCurrentAuth();
  const userData = useCommonDetails();

  let location = useLocation();
  if (!isAuthenticated.token) {
    return (
      <Navigate
        to={APP_ROUTES.Landing}
        state={{ from: location.pathname, search: location.search }}
        replace
      />
    );
  }
  if (!allowRoles.includes(userData.userType)) {
    return <Navigate to={APP_ROUTES.Dashboard} replace />;
  }
  if (userData.isDriverOnBoard && location.pathname == APP_ROUTES.Onboard) {
    return <Navigate to={APP_ROUTES.Dashboard} replace />;
  }
  return (
    <>
      <MainContainer>
        <>{children} </>
      </MainContainer>
    </>
  );
};

export default PrivateRoute;
