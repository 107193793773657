import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import NotFound from "./pages/NotFound/NotFound";
import PrivateRoute from "./RouteAccessor/PrivateRoute";
import { APP_ROUTES } from "./utils/constants";
import PublicRoute from "./RouteAccessor/PublicRoute";
import LandingPage from "./pages/LandingPage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import VerifyEmail from "./pages/VerifyEmail";
import Onboard from "./pages/Onboard";
import DriverProfile from "./pages/DriverProfile";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CreateAdvertiser from "./pages/CreateAdvertiser";
import CampaignList from "./pages/CampaignList";
import MyCampaings from "./pages/MyCampaigns";
import CreateCampaign from "./pages/CreateCampaign";
import AdvertiserCampaign from "./pages/AdvertiserCampaign";
import ApplyDriverList from "./pages/AdvertiserCampaign/ApplyDriverList";
import { USER_ROLE } from "./utils/constants";
import EditCampaigns from "./pages/EditCampaigns";
import CampaignDetails from "./pages/CampaignDetails";
import DriverContract from "./pages/DriverContract";
import DriverList from "./pages/DriverList";
import Profile from "./pages/Profile";

const ProjectRoutes = () => {
  return (
    <React.Suspense fallback={<>Loading...</>}>
      <Router>
        <Routes>
          <Route
            path={APP_ROUTES.Dashboard}
            element={
              <PrivateRoute
                allowRoles={[
                  USER_ROLE.DRIVER,
                  USER_ROLE.ADMIN,
                  USER_ROLE.ADVERTISER,
                ]}
              >
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path={APP_ROUTES.Onboard}
            element={
              <PrivateRoute allowRoles={[USER_ROLE.DRIVER]}>
                <Onboard />
              </PrivateRoute>
            }
          />
          <Route
            path={APP_ROUTES.DriverProfile}
            element={
              <PrivateRoute allowRoles={[USER_ROLE.DRIVER]}>
                <DriverProfile />
              </PrivateRoute>
            }
          />

          <Route
            path={APP_ROUTES.CreateAdvertisers}
            element={
              <PrivateRoute allowRoles={[USER_ROLE.ADMIN]}>
                <CreateAdvertiser />
              </PrivateRoute>
            }
          />
          <Route
            path={APP_ROUTES.CampaignsList}
            element={
              <PrivateRoute allowRoles={[USER_ROLE.DRIVER, USER_ROLE.ADMIN]}>
                <CampaignList />
              </PrivateRoute>
            }
          />
          <Route
            path={APP_ROUTES.Profile}
            element={
              <PrivateRoute allowRoles={[USER_ROLE.DRIVER, USER_ROLE.ADMIN]}>
                <Profile />
              </PrivateRoute>
            }
          />
          <Route
            path={APP_ROUTES.CampaignDetails}
            element={
              <PrivateRoute allowRoles={[USER_ROLE.DRIVER]}>
                <CampaignDetails />
              </PrivateRoute>
            }
          />
          <Route
            path={APP_ROUTES.EditCampaign}
            element={
              <PrivateRoute allowRoles={[USER_ROLE.ADVERTISER]}>
                <EditCampaigns />
              </PrivateRoute>
            }
          />
          <Route
            path={APP_ROUTES.MyCampaign}
            element={
              <PrivateRoute allowRoles={[USER_ROLE.DRIVER]}>
                <MyCampaings />
              </PrivateRoute>
            }
          />
          <Route
            path={APP_ROUTES.DriverContract}
            element={
              <PrivateRoute allowRoles={[USER_ROLE.DRIVER]}>
                <DriverContract />
              </PrivateRoute>
            }
          />
          <Route
            path={APP_ROUTES.CreateCampaign}
            element={
              <PrivateRoute
                allowRoles={[USER_ROLE.ADVERTISER, USER_ROLE.ADMIN]}
              >
                <CreateCampaign />
              </PrivateRoute>
            }
          />
          <Route
            path={APP_ROUTES.DriverList}
            element={
              <PrivateRoute allowRoles={[USER_ROLE.ADMIN]}>
                <DriverList />
              </PrivateRoute>
            }
          />
          <Route
            path={APP_ROUTES.AdvertiserCampaign}
            element={
              <PrivateRoute allowRoles={[USER_ROLE.ADVERTISER]}>
                <AdvertiserCampaign />
              </PrivateRoute>
            }
          />
          <Route
            path={APP_ROUTES.ApplyDriverList}
            element={
              <PrivateRoute allowRoles={[USER_ROLE.ADVERTISER]}>
                <ApplyDriverList />
              </PrivateRoute>
            }
          />
          <Route
            path={APP_ROUTES.Any}
            element={
              <PublicRoute>
                <NotFound />
              </PublicRoute>
            }
          />
          <Route
            path={APP_ROUTES.Landing}
            element={
              <PublicRoute>
                <LandingPage />
              </PublicRoute>
            }
          />
          <Route
            path={APP_ROUTES.Login}
            element={
              <PublicRoute>
                <LoginPage />
              </PublicRoute>
            }
          />
          <Route
            path={APP_ROUTES.PrivacyPolicy}
            element={
              <PublicRoute>
                <PrivacyPolicy />
              </PublicRoute>
            }
          />

          <Route
            path={APP_ROUTES.Register}
            element={
              <PublicRoute>
                <RegisterPage />
              </PublicRoute>
            }
          />
          <Route
            path={APP_ROUTES.VerifyEmail}
            element={
              <PublicRoute>
                <VerifyEmail />
              </PublicRoute>
            }
          />
        </Routes>
      </Router>
    </React.Suspense>
  );
};
export default ProjectRoutes;
