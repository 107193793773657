import {
  Box,
  Button,
  Grid,
  Skeleton,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import HttpService from "../../hooks/Https-services";
import { API_ROUTES, status_code } from "../../utils/constants";
import useCommonDetails from "../../hooks/useCommonDetails";
import Campaigns from "./Campaigns";
import Style from "../../style/Common.style";
import { LoadingButton } from "@mui/lab";

const MyCampaings = () => {
  const [tabs, setTabs] = useState(0);
  const [data, setData] = useState<any>([]);
  const userDetail = useCommonDetails();
  const [load, setLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const emptyArray = new Array(6).fill(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabs(newValue);
    setLoad(true);
    getCampaign(newValue, 1, 1);
  };
  useEffect(() => {
    getCampaign(status_code.request, 1);
  }, []);
  const handleNext = () => {
    getCampaign(tabs, page);
  };
  const getCampaign: any = async (code: any, page: any, flag?: any) => {
    try {
      setIsLoading(true);
      const data: any = {
        query: { userId: userDetail.id, isDeleted: false },
        options: {
          include: [
            {
              association: "advertise",
              required: true,
              model: "advertise",
            },
          ],
          page: page,
          paginate: 9,
        },
        isCountOnly: false,
      };
      data.query.status = code;
      const res = await HttpService.post(API_ROUTES.GetApplyCampaign, data);
      const temp: any = [];
      res.data.data?.map((ele: any) => {
        const name = ele.advertise.name;
        const url = ele.advertise.adImages[0].image;
        const car_type = ele.advertise.car_type.model
          ?.map((ele: any) => ele)
          .join(",");
        const added = ele.advertise.numberOfUserApplied;
        const location = ele.advertise.location.city;
        const cpm = ele.advertise.cpm;
        const id = ele.advertiseId;
        temp.push({
          name: name,
          url: url,
          car_type: car_type,
          added: added,
          location: location,
          cpm: cpm,
          id: id,
        });
      });
      if (temp.length == 0 || temp.length < 8) {
        setHasMore(false);
      } else {
        setHasMore(true);
        setPage(page + 1);
      }
      if (flag) {
        setData(temp);
      } else {
        setData((prevFilter: any) => [...prevFilter, ...temp]);
      }
      setLoad(false);
      setIsLoading(false);
    } catch (error) {
      setLoad(false);
      setIsLoading(false);
      console.log(error);
    }
  };
  return (
    <Box pt={2} pl={2}>
      <Box sx={Style.StickyHeaderStyle}>
        <Typography variant="h5" mb={1} fontWeight={500}>
          My Campaigns
        </Typography>
        <Tabs
          value={tabs}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Applied" value={0} />
          <Tab label="Approved" value={1} />
          <Tab label="Complated" value={2} />
        </Tabs>
      </Box>
      <Box
        my={3}
        sx={{
          // display: "flex",
          // flexWrap: "wrap",
          // justifyContent: "center",
          // gap: 5,
          overflow: "auto",
          // maxHeight: "calc(100vh - 235px)",
          width: "100%",
        }}
      >
        <Grid container spacing={3} pr={1}>
          {!load ? (
            data.length > 0 ? (
              <>
                {data.map((ele: any) => (
                  <Grid item xs={12} sm={6} lg={4}>
                    <Campaigns
                      url={ele.url}
                      name={ele.name}
                      location={ele.location}
                      cpm={ele.cpm}
                      added={ele.added}
                      car_type={ele.car_type}
                      id={ele.id}
                      code={tabs}
                      getCampaign={getCampaign}
                    />
                  </Grid>
                ))}
                <Grid item xs={12} sx={{ textAlign: "center" }}>
                  {hasMore && (
                    <LoadingButton
                      loading={isLoading}
                      variant="contained"
                      onClick={handleNext}
                      size="small"
                      sx={{ px: 5 }}
                    >
                      Load More
                    </LoadingButton>
                  )}
                </Grid>
              </>
            ) : (
              <Grid item xs={12}>
                <Typography variant="body1" textAlign="center">
                  No data found on this filte!
                </Typography>
              </Grid>
            )
          ) : (
            <Box sx={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
              <Grid container spacing={3} pr={1}>
                {emptyArray.map((ele: any) => (
                  <Grid item xs={12} sm={6} md={6} lg={4} sx={{ flexGrow: 1 }}>
                    <Skeleton
                      variant="rectangular"
                      height={400}
                      sx={{ maxWidth: "500px" }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default MyCampaings;
