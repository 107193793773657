import { Box, Button, Typography } from "@mui/material";
import React from "react";
import GoogleMapReact from "google-map-react";
import LineChart from "../../components/Graphs/LineChart";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  const { t, i18n } = useTranslation();
  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 11,
  };

  return (
    <Box p={2}>
      <Typography variant="h5" mb={1} fontWeight={500}>
        {t("dashboard.dashboard")}
      </Typography>
      <Box width="95%" height="65vh">
        <GoogleMapReact
          bootstrapURLKeys={{ key: "" }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
        ></GoogleMapReact>
      </Box>
      <Box sx={{ width: "100%", height: "30vh" }}>
        <LineChart />
      </Box>
    </Box>
  );
};

export default Dashboard;
