import { Box, List, ListItem, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { FC, useEffect } from "react";
import Style from "./PrivacyPolicy.style";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const { t, i18n } = useTranslation();
  function topFunction() {
    window.scroll({ top: 0, behavior: "smooth" });
  }

  const scrollToTop = () => {
    topFunction();
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <Container maxWidth="xl" sx={{ padding: { xs: 4, md: 3 } }}>
      <Box sx={{ p: { xs: 3, md: 8 } }}>
        <Typography
          variant="h1"
          sx={{
            fontSize: { xs: "36px", md: "48px" },
            color: "black",
            textAlign: "center",
            fontWeight: 500,
            my: { xs: 4, md: 6 },
          }}
        >
          {t("privacypolicy.head1")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: { xxs: "2rem", md: "4rem" },
            mt: { xxs: "2rem", md: "4rem" },
          }}
        >
          <Typography sx={Style.PrivacyParaStyle}>
            {t("privacypolicy.p1-1")}{" "}
            <a href="https://www.goads.fi">{t("privacypolicy.p1-2")}</a>{" "}
            {t("privacypolicy.p1-3")}
          </Typography>
          <Box sx={Style.PrivacyBox}>
            <Typography variant="h1" sx={Style.PrivacyHeading}>
              {t("privacypolicy.head2")}
            </Typography>
            <Typography sx={Style.PrivacyParaStyle}>
              {t("privacypolicy.p2-1")} <br />
              <ul>
                <li>{t("privacypolicy.li1")}</li>
                <li>{t("privacypolicy.li2")}</li>
                <li>{t("privacypolicy.li3")}</li>
                <li>{t("privacypolicy.li4")}</li>
              </ul>
              {t("privacypolicy.p2-2")}
            </Typography>
          </Box>
          <Box sx={Style.PrivacyBox}>
            <Typography variant="h1" sx={Style.PrivacyHeading}>
              {t("privacypolicy.head3")}
            </Typography>
            <Typography sx={Style.PrivacyParaStyle}>
              {t("privacypolicy.p3")}
              <br />
              <ul>
                <li>{t("privacypolicy.li3-1")}</li>
                <li>{t("privacypolicy.li3-2")}</li>
                <li>{t("privacypolicy.li3-3")}</li>
                <li>{t("privacypolicy.li3-4")}</li>
                <li>{t("privacypolicy.li3-5")}</li>
              </ul>
            </Typography>
          </Box>
          <Box sx={Style.PrivacyBox}>
            <Typography variant="h1" sx={Style.PrivacyHeading}>
              {t("privacypolicy.head4")}
            </Typography>
            <Typography sx={Style.PrivacyParaStyle}>
              {t("privacypolicy.p4")}
            </Typography>
          </Box>
          <Box sx={Style.PrivacyBox}>
            <Typography variant="h1" sx={Style.PrivacyHeading}>
              {t("privacypolicy.head5")}
            </Typography>
            <Typography sx={Style.PrivacyParaStyle}>
              {t("privacypolicy.p5")}
            </Typography>
          </Box>
          <Box sx={Style.PrivacyBox}>
            <Typography variant="h1" sx={Style.PrivacyHeading}>
              {t("privacypolicy.head6")}
            </Typography>
            <Typography sx={Style.PrivacyParaStyle}>
              {t("privacypolicy.p6")}
            </Typography>
          </Box>

          <Box sx={Style.PrivacyBox}>
            <Typography variant="h1" sx={Style.PrivacyHeading}>
              {t("privacypolicy.head7")}
            </Typography>
            <Typography sx={Style.PrivacyParaStyle}>
              {t("privacypolicy.p7-1")}{" "}
              <a href="mailto:info@goads.fi"> {t("privacypolicy.p7-2")}</a>.{" "}
              {t("privacypolicy.p7-3")}
            </Typography>
          </Box>

          <Box sx={Style.PrivacyBox}>
            <Typography variant="h1" sx={Style.PrivacyHeading}>
              {t("privacypolicy.head8")}
            </Typography>
            <Typography sx={Style.PrivacyParaStyle}>
              {t("privacypolicy.p8")}
            </Typography>
          </Box>

          <Box sx={Style.PrivacyBox}>
            <Typography variant="h1" sx={Style.PrivacyHeading}>
              {t("privacypolicy.head9")}
            </Typography>
            <Typography sx={Style.PrivacyParaStyle}>
              <Typography sx={Style.PrivacyParaStyle}>
                {t("privacypolicy.p9-1")}
                <a href="mailto:info@goads.fi"> {t("privacypolicy.p9-2")}</a>
              </Typography>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};
export default PrivacyPolicy;
