import {
  Box,
  IconButton,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import HttpService from "../../hooks/Https-services";
import { API_ROUTES, APP_ROUTES } from "../../utils/constants";

const DriverList = () => {
  const [data, setData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const getDriverList = async () => {
    try {
      const data = {
        query: { isDeleted: false },
        options: {
          include: [
            {
              association: "user",
              required: true,
              model: "user",
            },
          ],
        },
      };
      setIsLoading(true);
      const res = await HttpService.post(API_ROUTES.GetDriver, data);
      setData(res.data.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    getDriverList();
  }, []);
  return (
    <Box p={2}>
      <Typography variant="h5" mb={1} fontWeight={500}>
        Driver List
      </Typography>
      <Box sx={{ width: "100%" }}>
        {!isLoading ? (
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead
                sx={{
                  ".MuiTableCell-root": {
                    px: "5px",
                  },
                }}
              >
                <TableRow>
                  <TableCell align="center">No</TableCell>
                  <TableCell>User Name</TableCell>
                  <TableCell>Full Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Car Type</TableCell>
                  <TableCell>Location</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {data?.data?.map((ele: any, idx: any) => (
                  <TableRow
                    key={idx}
                    sx={{
                      "&:nth-of-type(odd)": {
                        backgroundColor: "#cfebff",
                      },
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell align="center">{idx + 1}</TableCell>
                    <TableCell>{ele?.user.username}</TableCell>
                    <TableCell>{ele?.user.name}</TableCell>
                    <TableCell>{ele?.user.email}</TableCell>
                    <TableCell>{ele?.user.mobileNo}</TableCell>
                    <TableCell>{ele?.car_type}</TableCell>
                    <TableCell>{ele?.user.location?.city}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Skeleton variant="rounded" width={"100%"} height={500} />
        )}
      </Box>
    </Box>
  );
};

export default DriverList;
