import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  Grid,
  IconButton,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import HttpService from "../../hooks/Https-services";
import { API_ROUTES } from "../../utils/constants";
import { usePlacesWidget } from "react-google-autocomplete";
import AdvertiserList from "./AdvertiserList";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import Style from "../../style/Common.style";

const CreateAdvertiser = () => {
  const [load, setLoad] = useState(false);
  const [load2, setLoad2] = useState(true);
  const [data, setData] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const [row, setRow] = useState(5);
  const [cpage, setCPage] = useState(1);
  const [tpage, setTPage] = useState(0);
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    reset,
    clearErrors,
    unregister,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data: any) => {
    try {
      setLoad(true);
      const dataBody = {
        username: data.username,
        password: data.password,
        email: data.email,
        name: data.name,
        location: watch("fullLocation"),
        mobileNo: data.number,
        companyName: data.company,
      };
      const res = await HttpService.post(
        API_ROUTES.CreateAdvertisement,
        dataBody
      );
      toast.success("Advertiser created successfully");
      getAdvetiser(cpage, row);
      setOpen(false);
      setLoad(false);
    } catch (error) {
      setLoad(false);
      console.log(error);
    }
  };
  const getAdvetiser = async (page: any, row: any) => {
    try {
      const body = {
        options: {
          page: page,
          paginate: row,
        },
      };
      const res = await HttpService.post(API_ROUTES.GetAdvertiser, body);
      setData(res.data.data);
      setTPage(res.data.data?.paginator.itemCount);
      setCPage(res.data.data?.paginator.currentPage);
      setRow(res.data.data?.paginator.perPage);
      setLoad2(false);
    } catch (error) {
      setLoad2(false);
      console.log(error);
    }
  };
  console.log(data?.data);

  useEffect(() => {
    getAdvetiser(cpage, row);
  }, []);
  const toAddress = (place: any) => {
    const { lat, lng } = place.geometry.location;
    const location = { lat: lat(), lon: lng() };
    return extractAddress(place.address_components, location);
  };
  const extractAddress = (addresses: any[], location: any) => {
    const address = {
      location,
      country: extractAddressData("country", addresses),
      street1: extractAddressStreetData(addresses),
      street2: extractAddressData("sublocality_level_2", addresses),
      city: extractAddressData("locality", addresses),
      state: extractAddressData("administrative_area_level_1", addresses),
      zip: extractAddressData("postal_code", addresses),
    };

    return address;
  };
  const extractAddressData = (name: string, addresses: any[]) =>
    addresses.find((c) => c.types.includes(name))?.long_name || "";

  const extractAddressStreetData = (addresses: any[]) => {
    const street = extractAddressData("sublocality_level_1", addresses);
    if (street) {
      const route = extractAddressData("route", addresses);
      if (route) {
        return `${street} ${route}`;
      }
    }
    return "";
  };
  const checkKeyDown = (e: any) => {
    if (e.key === "Enter") e.preventDefault();
  };
  const AutocompleteAdd = () => {
    const refs = usePlacesWidget<HTMLDivElement>({
      libraries: ["places"],
      options: { types: ["address"] },

      onPlaceSelected: (place: any) => {
        //set state
        console.log(place);
        let data = toAddress(place);
        setValue("fullLocation", data);
      },
    });

    return (
      <TextField
        fullWidth
        inputRef={refs.ref}
        label="Location"
        variant="outlined"
        autoComplete={"off"}
        size="medium"
        InputLabelProps={{ shrink: true }}
        InputProps={{
          style: { color: "black" },
        }}
        {...register("location", {
          required: "This field is required.",
        })}
        sx={{
          mt: 1,
          mb: 1,
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "black",
            },
          },
        }}
        error={!!errors.location}
        helperText={
          errors.location && (
            <Typography variant="caption" sx={{ color: "red" }}>
              {errors.location.message as any}
            </Typography>
          )
        }
      />
    );
  };
  return (
    <Box p={2}>
      <Typography variant="h5" mb={1} fontWeight={500}>
        Advertiser
      </Typography>
      <Box>
        <Dialog
          onClose={() => {
            setOpen(false);
            reset();
          }}
          open={open}
          sx={{
            "& .MuiDialog-paper": {
              backgroundColor: "transparent", // Set the background color to transparent
              boxShadow: "none", // Remove the box shadow
              borderRadius: "0", // Remove the border radius
            },
          }}
        >
          <Card
            sx={{
              px: { xs: 3, sm: 4 },
              py: { xs: 3, sm: 4 },
              maxWidth: "890px",
              borderRadius: "20px",
            }}
          >
            <form
              onSubmit={handleSubmit(onSubmit)}
              onKeyDown={(e) => checkKeyDown(e)}
            >
              <CardContent sx={{ p: "4px !important" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "ceneter",
                  }}
                >
                  <Typography variant="h6">Create Advertiser</Typography>
                  <IconButton
                    onClick={() => {
                      setOpen(false);
                      reset();
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
                <Grid container spacing={2} mt={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Name"
                      variant="outlined"
                      size="medium"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        style: { color: "black" },
                      }}
                      {...register("name", {
                        required: "This field is required.",
                      })}
                      sx={{
                        mt: 1,
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "black",
                          },
                        },
                      }}
                      error={!!errors.name}
                      helperText={
                        errors.name && (
                          <Typography variant="caption" sx={{ color: "red" }}>
                            {errors.name.message as any}
                          </Typography>
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Username"
                      variant="outlined"
                      size="medium"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        style: { color: "black" },
                      }}
                      {...register("username", {
                        required: "This field is required.",
                      })}
                      sx={{
                        mt: 1,
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "black",
                          },
                        },
                      }}
                      error={!!errors.username}
                      helperText={
                        errors.username && (
                          <Typography variant="caption" sx={{ color: "red" }}>
                            {errors.username.message as any}
                          </Typography>
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Email"
                      variant="outlined"
                      size="medium"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        style: { color: "black" },
                      }}
                      {...register("email", {
                        required: "This field is required.",
                        pattern: {
                          value:
                            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                          message: "Please enter valid email.",
                        },
                      })}
                      sx={{
                        mt: 1,
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "black",
                          },
                        },
                      }}
                      error={!!errors.email}
                      helperText={
                        errors.email && (
                          <Typography variant="caption" sx={{ color: "red" }}>
                            {errors.email.message as any}
                          </Typography>
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Phone Number"
                      variant="outlined"
                      size="medium"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        style: { color: "black" },
                      }}
                      {...register("number", {
                        required: "This field is required.",
                        pattern: {
                          value: /^\d{10}$/,
                          message: "Please enter a valid number",
                        },
                      })}
                      sx={{
                        mt: 1,
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "black",
                          },
                        },
                      }}
                      error={!!errors.number}
                      helperText={
                        errors.number && (
                          <Typography variant="caption" sx={{ color: "red" }}>
                            {errors.number.message as any}
                          </Typography>
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Password"
                      type="password"
                      size="medium"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        style: { color: "black" },
                      }}
                      {...register("password", {
                        required: "This field is required.",
                        minLength: {
                          value: 8,
                          message:
                            "Password must be at least 8 characters long.",
                        },
                      })}
                      sx={{
                        mt: 1,
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "black",
                          },
                        },
                      }}
                      error={!!errors.password}
                      helperText={
                        errors.password && (
                          <Typography variant="caption" sx={{ color: "red" }}>
                            {errors.password.message as any}
                          </Typography>
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <AutocompleteAdd />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Company Name"
                      variant="outlined"
                      size="medium"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        style: { color: "black" },
                      }}
                      {...register("company", {
                        required: "This field is required.",
                      })}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "black",
                          },
                        },
                      }}
                      error={!!errors.company}
                      helperText={
                        errors.company && (
                          <Typography variant="caption" sx={{ color: "red" }}>
                            {errors.company.message as any}
                          </Typography>
                        )
                      }
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions
                sx={{ flexDirection: "column", alignItems: "flex-end" }}
              >
                <LoadingButton
                  loading={load}
                  type="submit"
                  variant="contained"
                  size="large"
                >
                  Submit
                </LoadingButton>
              </CardActions>
            </form>
          </Card>
        </Dialog>
      </Box>
      <Box my={2}>
        <Box sx={Style.StickyHeaderStyle}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 3,
            }}
          >
            <Typography variant="h6">Advertiser List</Typography>
            <Button
              variant="contained"
              onClick={() => setOpen(true)}
              sx={{ textTransform: "none" }}
            >
              + Add New Advertiser
            </Button>
          </Box>
        </Box>
        {!load2 ? (
          data && (
            <AdvertiserList
              data={data}
              getAdvetiser={getAdvetiser}
              total={tpage}
              page={cpage}
              row={row}
            />
          )
        ) : (
          <Skeleton variant="rounded" width={"100%"} height={500} />
        )}
      </Box>
    </Box>
  );
};

export default CreateAdvertiser;
