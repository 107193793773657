import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import bg from "../../assets/images/car_campaign2.jpg";
import bg2 from "../../assets/images/car_campaign.jpg";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import NoCrashOutlinedIcon from "@mui/icons-material/NoCrashOutlined";
import CircleIcon from "@mui/icons-material/Circle";
import RouteIcon from "@mui/icons-material/Route";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import { useParams } from "react-router-dom";
import HttpService from "../../hooks/Https-services";
import { API_ROUTES } from "../../utils/constants";
import moment from "moment";
import DriverUploadCard from "./DriverUploadCard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./style.css";
import useCommonDetails from "../../hooks/useCommonDetails";
import { auto } from "@popperjs/core";

const CampaignDetails = () => {
  const [images, setImages] = useState<any>([]);
  const [data, setData] = useState<any>({});
  const [dataImg, setDataImg] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<any>(null);
  const [startDate, setStartDate] = useState<any>(null);
  const [img, setImg] = useState<any>(null);
  const [month, setMonth] = useState<any>(moment().format("MM"));
  const { id } = useParams();
  const userDetail = useCommonDetails();
  const emptyArr = new Array(5).fill(0);

  const imageeess = [
    {
      img: "https://chatmatrix.s3.ap-south-1.amazonaws.com/goAds/car_campaign3-1702296230173.jpg",
    },
    {
      img: "https://chatmatrix.s3.ap-south-1.amazonaws.com/goAds/car_campaign-1702296233088.jpg",
    },
  ];
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    // autoplay: true,
    autoplayspeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const getCampaignDetails = async () => {
    try {
      setIsLoading(true);
      const res = await HttpService.get(
        API_ROUTES.GetCampaigns.replace(":id", "" + id)
      );
      const tempData = res.data.data;
      const temp = {
        cpm: tempData.cpm,
        name: tempData.name,
        apply: tempData.numberOfUserApplied,
        earn: 200,
        car_type: tempData.car_type.model.map((e: any) => e).join(","),
        distance: "20 km",
        location: tempData.location.city,
      };
      setImages(tempData.adImages);
      getCampaignImage(moment().format("MM"));

      handleDates(tempData.createdAt);
      setData(temp);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  function generateRange(start: any, end: any) {
    return Array.from(
      { length: end - start + 1 },
      (_, index) =>
        start +
        index +
        " " +
        moment().format("MMM") +
        ", " +
        moment().format("YYYY")
    );
  }
  const handleImage = () => {
    // console.log(startDate);
    // console.log(dataImg);
    const temp: any = [];
    startDate.forEach((date: any) => {
      let idx = -1;
      dataImg.map((obj: any, idnx: any) => {
        if (obj.createdAt == date) {
          idx = idnx;
          return;
        }
      });
      if (idx > -1) {
        temp.push({
          date: date,
          img: dataImg[idx].images,
          id: dataImg[idx].id,
        });
      } else {
        temp.push({
          date: date,
          img: [],
          id: null,
        });
      }
    });
    setImg(temp);
    console.log(temp);
  };
  const handleDates = (e: any) => {
    const date1 = moment(e);
    const date2 = moment();
    const duration = moment.duration(date2.diff(date1));
    const months = duration.months();
    const start = months > 0 ? 1 : parseInt(date1.format("D"));
    const end = parseInt(date2.format("D"));
    const temp = generateRange(start, end);
    console.log(dataImg);
    setStartDate(temp.reverse());
  };
  const getCampaignImage = async (m: any) => {
    try {
      const dataBody = {
        query: {
          userId: userDetail.id,
          advertiseId: id,
        },
        filter: {
          month: "12",
        },
      };
      const res = await HttpService.post(API_ROUTES.GetCampaignImage, dataBody);
      const temp: any = [];
      res.data.data?.map((e: any) => {
        temp.push({
          createdAt: moment(e.createdAt).format("D MMM, YYYY"),
          images: e.Images,
          id: e.id,
        });
      });
      setDataImg(temp);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (dataImg) handleImage();
  }, [dataImg]);
  useEffect(() => {
    getCampaignDetails();
  }, []);
  return (
    <Box px={2}>
      <Typography variant="h5" my={2} fontWeight={500}>
        Campaign Detail Page
      </Typography>
      <Box px={2}>
        {!isLoading ? (
          <Card
            sx={{
              border: "0.352px solid",
              borderColor: "primary.main",
              backgroundColor: "whitesmoke",
              boxShadow:
                "0px 1.229px 2.09px 0px rgba(0, 0, 0, 0.00), 0px 5.408px 4.327px 0px rgba(0, 0, 0, 0.01), 0px 13.275px 8.629px 0px rgba(0, 0, 0, 0.01), 0px 25.567px 16.914px 0px rgba(0, 0, 0, 0.01), 0px 43.022px 31.099px 0px rgba(0, 0, 0, 0.02), 0px 66.377px 53.101px 0px rgba(0, 0, 0, 0.02)",
            }}
          >
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  px: { xs: 0, sm: 2 },
                  justifyContent: "center",
                  flexDirection: { xs: "column", md: "row" },
                }}
              >
                <Box
                  maxWidth={{ xs: "100%", md: "60%" }}
                  mt={{ xs: 0, md: 2.5 }}
                >
                  {images.length > 0 && (
                    <Slider {...settings}>
                      {images?.map((ele: any) => (
                        <Box key={ele}>
                          <Box p={0.5} mx={"auto"}>
                            <Box
                              src={ele.image}
                              component={"img"}
                              maxWidth={"100%"}
                              // height={{ xs: "unset", md: 330 }}
                              maxHeight={{ xs: "unset", md: 330 }}
                              sx={{
                                borderRadius: "8px",
                                objectFit: "fill",
                                float: "left",
                              }}
                            />
                          </Box>
                        </Box>
                      ))}
                    </Slider>
                  )}
                </Box>
                <Box maxWidth={"100%"} flexGrow={1}>
                  <Typography variant="h5" my={2} fontWeight={600}>
                    {data.name}
                  </Typography>

                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Stack spacing={1} direction="row" alignItems="center">
                        <AttachMoneyOutlinedIcon sx={{ fontSize: "28px" }} />
                        <Typography variant="body1" sx={{ fontWeight: "600" }}>
                          Cpm :
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="body1"
                        color={"gray"}
                        sx={{ fontWeight: "500" }}
                      >
                        {data.cpm} $
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={1} direction="row" alignItems="center">
                        <EmojiEventsOutlinedIcon sx={{ fontSize: "28px" }} />
                        <Typography variant="body1" sx={{ fontWeight: "600" }}>
                          Campaign Earnings :
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="body1"
                        color={"gray"}
                        sx={{ fontWeight: "500" }}
                      >
                        {data.earn} $
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={1} direction="row" alignItems="center">
                        <RouteIcon sx={{ fontSize: "28px" }} />
                        <Typography variant="body1" sx={{ fontWeight: "600" }}>
                          Distance Traveled :
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="body1"
                        color={"gray"}
                        sx={{ fontWeight: "500" }}
                      >
                        {data.distance}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={1} direction="row" alignItems="center">
                        <AccountCircleOutlinedIcon sx={{ fontSize: "28px" }} />
                        <Typography variant="body1" sx={{ fontWeight: "600" }}>
                          Applicants :
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="body1"
                        color={"gray"}
                        sx={{ fontWeight: "500" }}
                      >
                        {data.apply}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={1} direction="row" alignItems="center">
                        <NoCrashOutlinedIcon sx={{ fontSize: "28px" }} />
                        <Typography variant="body1" sx={{ fontWeight: "600" }}>
                          Car Type :
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="body1"
                        color={"gray"}
                        sx={{ fontWeight: "500" }}
                      >
                        {data.car_type}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={1} direction="row" alignItems="center">
                        <LocationOnOutlinedIcon sx={{ fontSize: "28px" }} />
                        <Typography variant="body1" sx={{ fontWeight: "600" }}>
                          Location :
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="body1"
                        color={"gray"}
                        sx={{ fontWeight: "500" }}
                      >
                        {data.location}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </CardContent>
          </Card>
        ) : (
          <Box>
            <Skeleton variant="rounded" width={"100%"} height={290} />
          </Box>
        )}
      </Box>
      {/* <Box>
        <Box maxWidth={"70%"} sx={{ margin: "0 auto" }}>
          <Typography variant="h5" my={2} fontWeight={500}>
            {data.name}
          </Typography>
          <Grid container spacing={1}>
            <Grid item md={3} lg={2} sx={{ flexWrap: "wrap" }}>
              <Stack spacing={1} direction="row" alignItems="center">
                <AttachMoneyOutlinedIcon sx={{ fontSize: "28px" }} />
                <Typography variant="body1" sx={{ fontWeight: "600" }}>
                  Cpm :
                </Typography>
              </Stack>
            </Grid>
            <Grid item md={3} lg={2}>
              <Typography
                variant="body1"
                color={"gray"}
                sx={{ fontWeight: "400" }}
              >
                {data.cpm} $
              </Typography>
            </Grid>
            <Grid item md={3} lg={3} sx={{ flexWrap: "wrap" }}>
              <Stack spacing={1} direction="row" alignItems="center">
                <EmojiEventsOutlinedIcon sx={{ fontSize: "28px" }} />
                <Typography variant="body1" sx={{ fontWeight: "600" }}>
                  Campaign Earnings :
                </Typography>
              </Stack>
            </Grid>
            <Grid item md={3} lg={2}>
              <Typography
                variant="body1"
                color={"gray"}
                sx={{ fontWeight: "400" }}
              >
                {data.earn} $
              </Typography>
            </Grid>

            <Grid item md={3} lg={2}>
              <Stack spacing={1} direction="row" alignItems="center">
                <AccountCircleOutlinedIcon sx={{ fontSize: "28px" }} />
                <Typography variant="body1" sx={{ fontWeight: "600" }}>
                  Applicants :
                </Typography>
              </Stack>
            </Grid>
            <Grid item md={3} lg={1}>
              <Typography
                variant="body1"
                component="span"
                color={"gray"}
                sx={{ fontWeight: "400" }}
              >
                {data.apply}
              </Typography>
            </Grid>
            <Grid item md={3} lg={2}>
              <Stack spacing={1} direction="row" alignItems="center">
                <NoCrashOutlinedIcon sx={{ fontSize: "28px" }} />
                <Typography variant="body1" sx={{ fontWeight: "600" }}>
                  Car Type :
                </Typography>
              </Stack>
            </Grid>
            <Grid item md={3} lg={2}>
              <Typography
                variant="body1"
                component="span"
                color={"gray"}
                sx={{ fontWeight: "400" }}
              >
                {data.car_type}
              </Typography>
            </Grid>
            <Grid item md={3} lg={3}>
              <Stack spacing={1} direction="row" alignItems="center">
                <RouteIcon sx={{ fontSize: "28px" }} />
                <Typography variant="body1" sx={{ fontWeight: "600" }}>
                  Distance Traveled :
                </Typography>
              </Stack>
            </Grid>
            <Grid item md={3} lg={2}>
              <Typography
                variant="body1"
                component="span"
                color={"gray"}
                sx={{ fontWeight: "400" }}
              >
                {data.distance}
              </Typography>
            </Grid>
            <Grid item md={3} lg={2}>
              <Stack spacing={1} direction="row" alignItems="center">
                <LocationOnOutlinedIcon sx={{ fontSize: "28px" }} />
                <Typography variant="body1" sx={{ fontWeight: "600" }}>
                  Location :
                </Typography>
              </Stack>
            </Grid>
            <Grid item md={3} lg={1}>
              <Typography
                variant="body1"
                component="span"
                color={"gray"}
                sx={{ fontWeight: "400" }}
              >
                {data.location}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box> */}
      <Box p={2} mt={2}>
        <Box>
          <Typography component="span">Month : </Typography>
          <Select size="small" value={month}>
            <MenuItem value={"01"} sx={{ mt: 0.4 }}>
              January
            </MenuItem>
            <MenuItem value={"02"} sx={{ mt: 0.4 }}>
              February
            </MenuItem>
            <MenuItem value={"03"} sx={{ mt: 0.4 }}>
              March
            </MenuItem>
            <MenuItem value={"04"} sx={{ mt: 0.4 }}>
              April
            </MenuItem>
            <MenuItem value={"05"} sx={{ mt: 0.4 }}>
              May
            </MenuItem>
            <MenuItem value={"12"} sx={{ mt: 0.4 }}>
              December
            </MenuItem>
          </Select>
        </Box>
        <Alert severity="warning" sx={{ mt: 2, justifyContent: "center" }}>
          Please ensure to add four pictures, one from each side,to verify the
          condition of the vehicle.
        </Alert>

        {!isLoading
          ? img?.map((ele: any) => (
              <DriverUploadCard date={ele.date} image={ele.img} ids={ele.id} />
            ))
          : emptyArr?.map((ele: any) => (
              <Box mt={1}>
                <Skeleton variant="rounded" width={"100%"} height={150} />
              </Box>
            ))}
      </Box>
    </Box>
  );
};

export default CampaignDetails;
