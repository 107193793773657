import { Box } from "@mui/material";
import React from "react";

import { APP_ROUTES } from "../utils/constants";
// import Header from '../components/common/Header';
// import Footer from '../components/common/Footer';
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import useCommonDetails from "../hooks/useCommonDetails";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { getCurrentAuth } from "../hooks/http-server";

const PublicRoute = ({ children }: { children: React.ReactElement }) => {
  const isAuthenticated = getCurrentAuth();
  const userData = useCommonDetails();

  let location = useLocation();
  if (isAuthenticated.token) {
    return (
      <Navigate
        to={APP_ROUTES.Dashboard}
        state={{ from: location.pathname, search: location.search }}
        replace
      />
    );
  }
  return (
    <Box
      sx={{
        flexGrow: 1,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {location.pathname == APP_ROUTES.Login ||
      location.pathname == APP_ROUTES.Register ? (
        <Box>{children}</Box>
      ) : (
        <>
          <Header />
          <Box flex={1}>{children}</Box>
          <Footer />
        </>
      )}
    </Box>
  );
};

export default PublicRoute;
