import {
  Box,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  Button,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import logo from "../../assets/image/icon.png";
import { useEffect, useState } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import axios from "../../utils/axios";

import { toast } from "react-toastify";
import { API_ROUTES, APP_ROUTES } from "../../utils/constants";
import HttpService from "../../hooks/Https-services";
const VerifyEmail = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const client = HttpService;
  const [isSkeleton, setIsSkeleton] = useState(false);
  const [isSucces, setIsSuccess] = useState(false);
  const navigate = useNavigate();
  const VerfiyEmailFunction = async () => {
    setIsSkeleton(true);
    try {
      if (searchParams.get("token")) {
        const res = await client.post(API_ROUTES.VerifyEmail, {
          token: searchParams.get("token"),
        });
        if (res) {
          setIsSuccess(true);
          toast.success("Email Verified Successfully");
          navigate(APP_ROUTES.Login);
        }
      }
      setIsSkeleton(false);
    } catch (error: any) {
      setIsSuccess(false);
      setIsSkeleton(false);
    }
  };
  useEffect(() => {
    VerfiyEmailFunction();
  }, []);
  return (
    <Box sx={{ minHeight: "100%" }}>
      <Grid container>
        <Grid item xs={12}>
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {isSkeleton ? (
                <CircularProgress
                  size={36}
                  sx={{
                    margin: "0 auto",
                    color: "#003876",
                  }}
                />
              ) : (
                <Box>
                  {isSucces ? (
                    <Typography variant="h3" sx={{ textAlign: "center" }}>
                      Email Verified Successfully
                    </Typography>
                  ) : (
                    <Box sx={{ textAlign: "center" }}>
                      <Typography variant="h5" sx={{ textAlign: "center" }}>
                        Email Veififaction has been failed
                      </Typography>
                      <Button
                        variant="contained"
                        onClick={() => navigate(APP_ROUTES.Register)}
                      >
                        Try again
                      </Button>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default VerifyEmail;
